<template>
  <div class="paste-image">
    <PasteUploadBar
      :paste-id="pasteId"
      :show-upload-progress="showUploadProgress || isEncrypting"
      :upload-progress="uploadProgress || encryptProgress"
      :upload-task="uploadTask"
      :is-encrypting="isEncrypting"
    />
    <div ref="pasteImageContainer" class="paste-image__container">
      <PasteImageDiv
        v-if="paste"
        ref="pasteDiv"
        :paste="paste"
        :paste-id="pasteId"
        :on-image-rendered="onImageRendered"
      />
      <LoadingSpinner v-show="!imageRendered" :color="'#fff'" :size="26" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { Paste, PasteDownloadStatus, PasteUploadStatus } from '/@/interfaces'

  // Props

  const props = withDefaults(
    defineProps<{
      paste: Paste
      pasteId: string
      uploadStatus?: PasteUploadStatus
      downloadStatus?: PasteDownloadStatus
      onImageRendered: () => void
    }>(),
    {
      uploadStatus: {
        encrypting: false,
        uploadAmount: 0,
        uploadTask: null,
      },
      downloadStatus: {
        isDownloading: false,
        downloadAmount: 0,
      },
    },
  )

  // Data

  const pasteDiv = ref(null)
  const imageRendered = ref(false)

  // Computed

  const uploadProgress = computed(() => {
    return props.uploadStatus?.uploadAmount
  })

  const uploadTask = computed(() => props.uploadStatus?.uploadTask)

  const showUploadProgress = computed(() => props.uploadStatus?.isUploading)

  const isEncrypting = computed(() => props.uploadStatus?.encrypting)

  const encryptProgress = computed(() => {
    return props.uploadStatus?.cryptAmount
  })

  const imageHeight = computed(() => {
    // This is used to make sure the page doesn't jump around
    // after an image had loaded and causing a layout shift.
    // If there is no image rendered it stays at 500px,
    // if image is rendered, div height matches image height.
    return imageRendered.value ? '100%' : '400px'
  })

  // Methods

  const onImageRendered = () => {
    imageRendered.value = true
    props.onImageRendered()
  }
</script>

<style lang="scss">
  .paste-image {
    width: 450px;
    margin-top: 8px;
    position: relative;

    &__container {
      width: 100%;
      //height: v-bind(imageHeight);
      max-height: 500px;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100px;
      overflow: hidden;
      border-radius: 14px;
      background-color: #222222;

      & > .paste-image-img {
        border-radius: 14px;
      }

      a {
        color: #71efde;
      }

      &--loading {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: $mobile) {
      width: unset;
    }
  }
</style>
