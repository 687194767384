const defaultSettings = {
  allowClipboardAccess: true,
  autoClipboardRead: true,
  autoDownload: false,
  clickToCopy: false,
  devLogging: false,
  showNotifications: false,
}

export { defaultSettings }
