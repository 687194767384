import dayjs from 'dayjs'

const DEFAULT_TIME_TAKEN = 2

const isNewerThanTenSecondsOld = (
  timeTaken = DEFAULT_TIME_TAKEN,
  pasteCreatedAt: string
) => {
  // Get the time the paste was created minus the time it took to download
  // and minus 10 seconds and determine whether the paste was created any more
  // than 10 seconds ago, if it was, don't download it, might not be a new paste
  const tenSecondsAgo = dayjs(new Date()).subtract(10, 'seconds')
  const tenSecondsAgoCorrected = dayjs(tenSecondsAgo).subtract(
    timeTaken,
    'seconds'
  )
  return dayjs(pasteCreatedAt).isAfter(tenSecondsAgoCorrected)
}

export default isNewerThanTenSecondsOld
