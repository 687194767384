const getFileTypeFromBlob = (item: Blob[] | File): string[] | string => {
  const types: string[] = []
  if ((item as Blob[]).length >= 1) {
    ;(item as Blob[]).forEach((item) => types.push(item.type))
    return types
  } else {
    return (item as File).type
  }
}

const getFileType = (
  contentType: string
): 'string' | 'image' | 'file' | 'unknown' => {
  if (contentType === 'text/string' || contentType === 'text/plain')
    return 'string'
  else if (contentType.startsWith('image')) return 'image'
  else if (!contentType.startsWith('text') || !contentType.startsWith('image'))
    return 'file'
  else return 'unknown'
}

export { getFileTypeFromBlob, getFileType }
