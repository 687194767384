import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { Device } from '/@/interfaces'

interface StorageLimits {
  max: number
  used: number
}

interface User {
  connections: any
  dbKey: string
  dbSessionId: string
  email: string
  encryptionSessionId: string
  hasEncryptionKey: string
  lastOnline: string
  settings: string
  storageLimits: StorageLimits
  updatedAt: string
  username: string
  devices: Record<string, Device>
}
export const useUserStore = defineStore('user', () => {
  const user = ref<User>()
  const userId = ref('')
  const userEmail = computed(() => user.value?.email)
  const userName = computed(() => user.value?.username)
  const storageLimits = computed(() => user.value?.storageLimits)
  const loggedIn = ref(false)

  return {
    user,
    userId,
    userEmail,
    userName,
    loggedIn,
    storageLimits,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
