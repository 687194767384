<template>
  <div class="paste-file">
    <div class="paste-file__container">
      <div class="paste-file__container-content">
        <i-feather-file class="paste-file__filetype" alt="Image" />
        <h3 class="paste-file__name truncate">
          {{ fileName }}
        </h3>
        <div
          v-if="isEncrypting || isDecrypting || showDownloadProgress"
          class="paste-file__encrypting"
        >
          <LoadingSpinner :size="16" :color="'#fff'" />
          <h4 v-show="isEncrypting">Encrypting</h4>
          <h4 v-show="isDecrypting">Decrypting</h4>
          <h4 v-show="showDownloadProgress">Downloading</h4>
        </div>
        <UploadSpinner
          v-show="showUploadProgress"
          :upload-progress="uploadProgress"
          :size="16"
        />
        <UploadControls
          v-if="showUploadProgress"
          :upload-task="uploadTask"
          :paste-id="paste.id"
        />
      </div>
      <ProgressBar
        v-if="
          isEncrypting ||
          isDecrypting ||
          showUploadProgress ||
          showDownloadProgress
        "
        :progress="
          encryptProgress ||
          decryptProgress ||
          uploadProgress ||
          downloadProgress
        "
        :full-width="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { defineProps, onBeforeMount, computed } from 'vue'
  import type { PropType } from 'vue'
  import getFilename from '../../utils/files/getFilename'
  import { PasteDownloadStatus, PasteUploadStatus } from '/@/interfaces'

  const props = defineProps({
    paste: {
      type: Object,
      required: true,
    },
    uploadStatus: {
      type: Object as PropType<PasteUploadStatus>,
      required: false,
      default: () => {
        return { encrypting: false, uploadAmount: 0, uploadTask: null }
      },
    },
    downloadStatus: {
      type: Object as PropType<PasteDownloadStatus>,
      required: false,
      default: () => {
        return { isDownloading: false, downloadAmount: 0 }
      },
    },
    pasteId: {
      type: String,
      required: true,
    },
  })

  const uploadTask = computed(() => props.uploadStatus?.uploadTask)
  const fileName = computed(() => getFilename(props.paste.content))

  // Upload Status

  const showUploadProgress = computed(() => props.uploadStatus?.isUploading)
  const uploadProgress = computed(() => {
    return props.uploadStatus?.uploadAmount
  })

  // Download Status

  const showDownloadProgress = computed(
    () => props.downloadStatus?.isDownloading,
  )
  const downloadProgress = computed(() => {
    return parseFloat(props.downloadStatus?.downloadAmount)
  })

  // Encrypting Status

  const isEncrypting = computed(() => props.uploadStatus?.encrypting)
  const encryptProgress = computed(() => {
    return props.uploadStatus?.cryptAmount
  })

  // Decrypting Status

  const isDecrypting = computed(() => props.downloadStatus?.decrypting)
  const decryptProgress = computed(() => {
    return props.downloadStatus?.cryptAmount
  })

  onBeforeMount(() => {
    // autoDownload()
  })
</script>

<style lang="scss">
  .paste-file {
    display: flex;
    align-items: center;
    min-width: 340px;
    max-width: 665px;

    &__name {
      margin-right: auto;
      font-weight: 100;
      padding-right: 14px;
    }

    &__encrypting {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__container {
      min-height: 48px;
      width: -webkit-fill-available;
      font-size: 16px;

      a {
        color: #71efde;
      }

      .paste-file__filetype {
        height: 24px;
        width: 24px;
        min-width: 24px;
        margin-right: 6px;
      }

      .progress-bar {
        width: 100%;

        &__percent {
          margin: -5px 0 0 0;
        }
      }

      @media (max-width: $mobile) {
        max-width: 100%;
      }

      &--grid {
        display: block;
        position: absolute;
        bottom: -9px;
        left: 8px;
        width: 70%;

        .pasta-icon,
        h3 {
          display: none;
        }

        @media (max-width: $mobile) {
          width: 100%;
          left: 0;
          bottom: -23px;
          .loading-spinner {
            display: none;
          }
          .progress-bar__percent {
            display: none;
          }
          .progress-bar {
            width: 100% !important;
          }
          .progress-bar__meter {
            border-radius: 0 !important;
          }
          .progress-bar__background {
            border-radius: 0 !important;
          }
        }
      }
    }

    &__container-content {
      display: flex;
      align-items: center;
      margin-top: 8px;
    }
  }
</style>

<style>
  /* Horizontal alignment of the cancel button specific to this component */
  .paste-options__cancel {
    margin-right: 0 !important;
  }
</style>
