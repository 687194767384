import SealdSDK, {
  // @ts-ignore -- seems to think this is not exported?
  createLocalStorageEncryptionSessionCache,
} from '@seald-io/sdk'
import SealdSDKPluginSSKSPassword from '@seald-io/sdk-plugin-ssks-password'
import sealdConfig from '/@/config/sealdConfig'
import { getAuth } from 'firebase/auth'
// @ts-ignore
import { SealdSDK as Seald } from '@seald-io/sdk/lib/main.SealdSDK'
import { EncryptionSession } from '@seald-io/sdk/lib/main'
import { customAlphabet } from 'nanoid'
import { useUserStore } from '/@/store/user'
import { databaseService } from '/@/services/index'
import { usePasteListStore } from '/@/store/pasteList'
import { Paste } from '/@/interfaces'

class SealdService {
  public seald: null | Seald = null
  public session: null | EncryptionSession = null
  private tmpKey = ''

  public set key(key: string) {
    this.tmpKey = key
  }

  get status() {
    return this.seald?.registrationStatus()
  }

  isPasteEncrypted(paste: Paste) {
    const content = paste.content
    try {
      const json = JSON.parse(content)
      if ('sessionId' in json) return true
    } catch (_error) {
      return false
    }
  }

  generateDBKey() {
    return customAlphabet(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()_+{}[]<>?',
      32,
    )()
  }

  generateShareKey() {
    const length = Math.floor(Math.random() * (32 - 26 + 1) + 26)
    return customAlphabet(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@$&-?',
      length,
    )()
  }

  async init(dbKey: string, encryptionSessionId: string) {
    if (this.seald) return
    this.seald = SealdSDK({
      appId: sealdConfig.appId,
      apiURL: sealdConfig.apiUrl,
      databaseKey: dbKey,
      databasePath: `encryption-session-${encryptionSessionId}`,
      encryptionSessionCacheTTL: -1,
      createEncryptionSessionCache: createLocalStorageEncryptionSessionCache,
      plugins: [SealdSDKPluginSSKSPassword(sealdConfig.keyStorageURL)],
    })
    await this.seald.initialize()
  }

  // This function sets up the user for the first time, only to be called
  // from the EncryptionKey component.
  async firstTimeUserSetup(encryptionKey: string) {
    const userStore = useUserStore()
    console.log(userStore.userEmail)
    if (!userStore.userEmail) return
    await this.createSealdIdentity(userStore.userEmail)
    await this.createSealdUserWithPassword(encryptionKey)
    await this.createSession()
  }

  deserializeSession(session: string) {
    this.session = this.seald.utils.deserializeSession(session)
  }

  async createSealdIdentity(displayName: string) {
    if (!this.seald) return
    const jwt = await databaseService.getJWT()
    if (!jwt) return
    await this.seald.initiateIdentity({ signupJWT: jwt, displayName })
  }

  async getSealdIdentity(key: string) {
    const userStore = useUserStore()
    await this.seald.ssksPassword.retrieveIdentity({
      userId: userStore.userId,
      password: key,
    })
  }

  async currentAccountInfo() {
    try {
      return await this.seald?.getCurrentAccountInfo()
    } catch (e) {
      return false
    }
  }

  async createSealdUserWithPassword(password: string) {
    const auth = getAuth()
    const userId = auth.currentUser?.uid
    if (!this.seald) return
    await this.seald.ssksPassword.saveIdentity({
      userId,
      password,
    })
  }

  async createSession(): Promise<void> {
    const userStore = useUserStore()
    const auth = getAuth()
    this.session = await this.seald.createEncryptionSession(
      {
        userIds: [userStore.userId], // recipients: users authorized to decrypt the
        // session messages (the user who creates the session is automatically added)
      },
      {
        metadata: `${auth.currentUser
          ?.email}-session-${new Date().toISOString()}`,
        encryptForSelf: true,
      },
    )
  }

  async setEncryptionSession(encryptionSessionId: string) {
    this.session = await this.seald.retrieveEncryptionSession({
      sessionId: encryptionSessionId,
      useCache: true,
    })
    if (!this.session) throw new Error('No session found!')
  }

  async waitForSession() {
    let count = 0
    if (this.session) return this.session
    return new Promise((resolve, reject) => {
      const wait = () => {
        if (this.session) return resolve(this.session)
        count++
        if (count > 10000) reject()
        setTimeout(wait, 80)
      }
      wait()
    })
  }

  async encryptMessage(text: string) {
    await this.waitForSession()
    return this.session?.encryptMessage(text)
  }

  async decryptMessage(text: string) {
    await this.waitForSession()
    return this.session?.decryptMessage(text)
  }

  async encryptFile(file: File, fileName: string, id: string) {
    await this.waitForSession()
    return this.session?.encryptFile(file, fileName, {
      progressCallback: (progress) => this.onEncryptingCallback(id, progress),
    })
  }

  async decryptFile(file: File | Blob, id: string) {
    await this.waitForSession()
    if (!this.session) return
    const {
      data, // An instance of Buffer containing the clear text data
      // type, // 'buffer'
      // size, // 19
      // sessionId, // the encryption session ID
      // filename, // 'SecretFile.txt'
    } = await this.session.decryptFile(file, (progress: number) =>
      this.onDecryptingCallback(id, progress),
    )
    return data
  }

  onEncryptingCallback = (id: string, progress: number) => {
    const pasteListStore = usePasteListStore()
    const percentage = parseFloat((progress * 100).toFixed())
    pasteListStore.updateEncryptingPasteStatus(id, percentage)
  }

  onDecryptingCallback = (id: string, progress: number) => {
    const pasteListStore = usePasteListStore()
    const percentage = parseFloat((progress * 100).toFixed())
    pasteListStore.updateDecryptingPasteStatus(id, percentage)
  }

  addSymEncKeyToSession = async (key: string) => {
    if (!this.session) return
    return await this.session.addSymEncKey({
      password: key,
      rights: { read: true, forward: false, revoke: true },
    })
  }

  deleteSymEncKeyFromSession = async (keyId: string) => {
    if (!this.session) return
    await this.session.deleteSymEncKey(keyId)
  }

  listSymEncKeyFromSession = async () => {
    if (!this.session) return
    return await this.session.listSymEncKeys()
  }

  deleteAllSharedKeysFromSession = async () => {
    if (!this.session) return
    const keys = await this.session.listSymEncKeys()
    for (const key of keys) {
      await this.deleteSymEncKeyFromSession(key.id)
      console.info('Deleted key: ', key.id)
    }
  }

  exportIdentity() {
    if (!this.seald) return
    return this.seald.exportIdentity()
  }

  async importIdentity(identity: string) {
    const buffer = await import('buffer/')
    const parsedId = <{ data: number[] }>JSON.parse(identity)
    if (!this.seald || !parsedId.data || !buffer) return
    // Convert array of numbers to a node poly-filled buffer
    const idBuffer = buffer.Buffer.from(parsedId.data)
    await this.seald.importIdentity(idBuffer)
  }
}

export default new SealdService()
