<template>
  <!-- https://codepen.io/vram1980/pen/Kyaie thanks!! -->
  <div class="live-indicator" role="img">
    <div
      v-tippy="{
        content: tooltipText,
        delay: [1000, 250],
        placement: 'bottom',
      }"
      :class="[!isConnected ? 'ring ring-off' : 'ring']"
    />
    <div
      :class="[
        !isConnected ? 'circle circle-off' : 'circle',
        isOfflineMode ? 'circle circle-offline' : 'circle',
      ]"
    ></div>
  </div>
</template>

<script>
  import { defineComponent, computed } from 'vue'
  import { directive } from 'vue-tippy'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'

  export default defineComponent({
    name: 'LiveIndicator',
    directives: {
      tippy: directive,
    },
    setup() {
      const appState = useAppStateStore()
      const { isConnected, isOfflineMode } = storeToRefs(appState)
      const tooltipText = computed(() => {
        if (isOfflineMode.value) {
          return 'Offline mode'
        }
        if (!isConnected.value && !isOfflineMode.value) {
          return 'Not connected'
        }
        return 'Connected'
      })
      return { isConnected, isOfflineMode, tooltipText }
    },
  })
</script>

<style lang="scss">
  .live-indicator {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ring {
      position: absolute;
      border: 1px solid #62bd19;
      border-radius: 30px;
      height: 25px;
      width: 25px;
      animation: pulsate 3s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
    }

    .circle {
      margin: auto;
      width: 15px;
      height: 15px;
      background-color: $success;
      border-radius: 50%;
      transition: background-color 250ms ease-in-out;
    }

    .ring-off {
      animation: none !important;
    }

    .circle-off {
      background-color: #ffc107 !important;
    }

    .circle-offline {
      background-color: $alert !important;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
</style>
