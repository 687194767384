<template>
  <div v-if="notification" class="notification notification-pill">
    {{ notification.message }}
    <div class="notification__actions">
      <div
        v-for="action in notification.actions"
        :key="action.buttonText"
        class="notification__actions-action"
        @click="action.onClick()"
      >
        {{ action.buttonText }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'

  const appState = useAppStateStore()
  const { notification } = storeToRefs(appState)
</script>

<style lang="scss">
  .notification {
    position: fixed;
    top: 18px;
    &__actions {
      display: flex;
      align-items: center;
      margin-left: 16px;
      .notification__actions-action {
        text-transform: uppercase;
        color: #e2e2e2e2;
        font-weight: bold;
        cursor: pointer;
        &:not(last-child) {
          margin-right: 8px;
        }
        &:hover {
          color: grey;
        }
      }
    }
    @media (max-width: $mobile) {
      top: 12px;
    }
  }
</style>
