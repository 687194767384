import { defineAsyncComponent } from 'vue'

export const ShareQR = defineAsyncComponent(() => import('./Share/ShareQR.vue'))
export const LogMeInQR = defineAsyncComponent(
  () => import('./Authentication/QR/LogMeInQR.vue'),
)

export const QRScanner = defineAsyncComponent(
  () => import('./Authentication/QRScanner/QRScanner.vue'),
)
export const MainSettings = defineAsyncComponent(
  () => import('./Settings/MainSettings.vue'),
)
export const RoomSettings = defineAsyncComponent(
  () => import('./Settings/RoomSettings/RoomSettings.vue'),
)
export const CreateRoom = defineAsyncComponent(
  () => import('./Settings/RoomSettings/CreateRoom.vue'),
)
export const MobilePasteActions = defineAsyncComponent(
  () => import('./Mobile/MobilePasteActions.vue'),
)
export const MaintenanceMode = defineAsyncComponent(
  () => import('./MaintenanceMode.vue'),
)
export const Login = defineAsyncComponent(
  () => import('./Authentication/Login.vue'),
)
export const Signup = defineAsyncComponent(
  () => import('./Authentication/Signup.vue'),
)
export const ResetPassword = defineAsyncComponent(
  () => import('./Authentication/ResetPassword.vue'),
)
export const EncryptionKey = defineAsyncComponent(
  () => import('./Authentication/EncryptionKey.vue'),
)
export const EncryptionKeyInput = defineAsyncComponent(
  () => import('./Authentication/EncryptionKeyInput.vue'),
)
export const VerifyEmail = defineAsyncComponent(
  () => import('./Authentication/VerifyEmail.vue'),
)
export const AskToVerifyEmail = defineAsyncComponent(
  () => import('./Authentication/AskToVerifyEmail.vue'),
)
export const FileDrop = defineAsyncComponent(() => import('./FileDrop.vue'))
export const UpdateOverlay = defineAsyncComponent(
  () => import('./UpdateOverlay.vue'),
)
export const LoadingOverlay = defineAsyncComponent(
  () => import('./LoadingOverlay.vue'),
)
