<template>
  <div class="loading-spinner" :style="`height: ${size}px; width: ${size}px;`">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :viewbox="viewBox"
      :stroke="color"
      :stroke-width="strokeWidth"
      :stroke-dasharray="strokeDasharray"
    >
      <circle cx="50%" cy="50%" r="40%" />
    </svg>
  </div>
</template>

<script>
  import { defineComponent, computed } from 'vue'

  export default defineComponent({
    name: 'LoadingSpinner',
    props: {
      color: {
        type: String,
        required: false,
        default: '#282829',
      },
      size: {
        type: Number,
        required: false,
        default: 40,
      },
    },
    setup(props) {
      const compute = {
        viewBox: computed(() => `0 0 ${props.size} ${props.size}`),
        strokeWidth: computed(() => (props.size <= 20 ? '2px' : '4px')),
        strokeDasharray: computed(() => (props.size <= 20 ? 58 : 112)),
      }

      return {
        ...compute,
      }
    },
  })
</script>

<style scoped lang="scss">
  .loading-spinner {
    svg {
      height: 100%;
      width: 100%;
      circle {
        fill: none;
        transform-origin: 50%;
        stroke-dashoffset: 215;
        shape-rendering: geometricPrecision;
        animation: animate 3s linear infinite;
      }
    }
  }
  @keyframes animate {
    to {
      stroke-dashoffset: 0;
      transform: rotate(360deg);
    }
  }
</style>
