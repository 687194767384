import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useUserStore } from '/@/store/user'
import { Device } from '/@/interfaces'
import logout from '/@/utils/logout'

interface DeviceState {
  [key: string]: any
}

export const useDeviceStore = defineStore('deviceStore', (): DeviceState => {
  const userStore = useUserStore()

  const currentDevice = ref<Device>()
  const currentDeviceId = computed(() => currentDevice.value?.id)
  const currentFcmId = computed(() => currentDevice.value?.fcmId)
  const devicesUnfiltered = computed(() =>
    Object.entries(userStore.user?.devices || {}).map(
      (device: [string, Device]) => {
        return {
          id: device[0],
          ...device[1],
        }
      },
    ),
  )
  const devices = computed(() =>
    devicesUnfiltered.value.filter((device: Device) => !device.shouldLogOut),
  ) // Do not list devices that are marked for deletion

  // Log out current device if it's marked as shouldLogOut
  watch(devicesUnfiltered, async (devices) => {
    const currentDeviceRemote = devices.find(
      (device) => device.id === currentDeviceId.value,
    )
    if (currentDeviceRemote?.shouldLogOut) {
      await logout()
    }
  })

  return {
    devices,
    currentDevice,
    currentDeviceId,
    currentFcmId,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStore, import.meta.hot))
}
