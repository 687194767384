<template>
  <div class="windows-buttons">
    <i-mdi-window-close @click="close" />
    <i-mdi-window-maximize @click="maximize" />
    <i-mdi-window-minimize @click="minimize" />
  </div>
</template>

<script setup lang="ts">
  import { ipcService } from '../../services'

  const close = () => {
    ipcService.handleWindowEvents('close')
  }

  const maximize = () => {
    ipcService.handleWindowEvents('maximize')
  }

  const minimize = () => {
    ipcService.handleWindowEvents('minimize')
  }
</script>

<style scoped lang="scss">
  .windows-buttons {
    display: flex;
    align-items: start;
    gap: 6px;
    transform: translate(10px, 10px);
    color: #727272;
    z-index: 10;
    .pasta-icon {
      height: 24px;
      width: 24px;
      &:hover {
        color: #b5b5b5;
      }
    }
  }
</style>
