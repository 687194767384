<template>
  <div>
    <div class="overlay overlay--background"></div>
    <div class="overlay">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    opacity: { type: Number, required: false, default: 45 },
    color: { type: String, required: false, default: '#000000' },
  })

  const opacityValue = computed(() => props.opacity + '%')
</script>

<style scoped lang="scss">
  .overlay {
    opacity: 100%;

    &--background {
      background-color: v-bind(color);
      opacity: v-bind(opacityValue);
    }
  }
</style>
