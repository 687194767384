import devlog from '/@/utils/log'
import {
  getDatabase,
  ref,
  onValue,
  push,
  onDisconnect,
  set,
  serverTimestamp,
} from 'firebase/database'
import { ipcService } from '/@/services/index'
import { useUserStore } from '/@/store/user'
import { useAppStateStore } from '/@/store/appState'
import { getApp } from 'firebase/app'
import firebaseConfig from '/@/config/firebaseConfig'

class offlineModeService {
  private offlineModeDetectorEnabled = false
  setupOfflineDetector() {
    if (this.offlineModeDetectorEnabled) return
    const userStore = useUserStore()
    // More info about how this works here https://firebase.google.com/docs/database/web/offline-capabilities
    const app = getApp()
    const db = getDatabase(app, firebaseConfig.databaseURL)
    const myConnectionsRef = ref(db, `users/${userStore.userId}/connections`)
    // stores the timestamp of my last disconnect (the last time I was seen online)
    const lastOnlineRef = ref(db, `users/${userStore.userId}/lastOnline`)
    const connectedRef = ref(db, '.info/connected')
    onValue(connectedRef, async (snap) => {
      try {
        if (snap.val() === true) {
          this.goBackOnline()
          devlog('service', 'offlineMode', 'Going back online at ' + new Date())
          const con = push(myConnectionsRef)
          // Remove device on disconnect
          await onDisconnect(con).remove()
          // Add this device to my connections list
          // this value could contain info about the device or a timestamp too
          await set(con, true)
          // When I disconnect, update the last time I was seen online
          await onDisconnect(lastOnlineRef).set(serverTimestamp())
        } else {
          this.goOffline()
          devlog('service', 'offlineMode', 'Going offline at ' + new Date())
        }
      } catch (_) {
        return
      }
    })
    this.offlineModeDetectorEnabled = true
  }

  goBackOnline() {
    const userStore = useUserStore()
    const appState = useAppStateStore()
    devlog('service', 'offlineMode', 'Going back online at ' + new Date())
    appState.isConnected = true
    appState.isOfflineMode = false
    ipcService.updateTrayMenuConnectedStatus('connected')
    if (!userStore.loggedIn) window.location.reload()
  }

  goOffline() {
    const appState = useAppStateStore()
    appState.isConnected = false
    appState.isOfflineMode = true
    ipcService.updateTrayMenuConnectedStatus('disconnected')
  }
}

export default new offlineModeService()
