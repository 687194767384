<template>
  <div class="upload-controls">
    <i-feather-play
      v-show="uploadIsPaused"
      class="paste-options__resume"
      alt="Resume upload"
      @click="resumeUpload"
    />
    <i-feather-pause
      v-show="!uploadIsPaused"
      class="paste-options__pause"
      alt="Pause upload"
      @click="pauseUpload"
    />
    <i-feather-x
      class="paste-options__cancel"
      alt="Cancel upload"
      @click="cancelUpload"
    />
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs } from 'vue'
  import { usePasteListStore } from '/@/store/pasteList'

  export default defineComponent({
    name: 'UploadControls',
    props: {
      uploadTask: {
        type: Object,
        required: true,
      },
      pasteId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const pasteListStore = usePasteListStore()

      const data = reactive({
        uploadIsPaused: false,
      })

      const methods = {
        pauseUpload(e) {
          e.stopPropagation()
          props.uploadTask?.pause()
          data.uploadIsPaused = true
        },
        resumeUpload(e) {
          e.stopPropagation()
          props.uploadTask?.resume()
          data.uploadIsPaused = false
        },
        cancelUpload(e) {
          e.stopPropagation()
          props.uploadTask?.cancel()
          pasteListStore.removeUploadingPaste(props.pasteId)
        },
      }

      return {
        ...toRefs(data),
        ...methods,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .upload-controls {
    &__img {
      margin-top: 5px;
      margin-left: 3px;
      margin-right: -9px;
    }
  }

  .pasta-icon {
    cursor: pointer;
    &:hover {
      color: darken(#ececec, 10%);
    }
  }
  .paste-options__cancel {
    margin-right: 8px;
  }
  .paste-options__cancel {
    transform: scale(1.2);
    margin-right: 18px;
  }
  .paste-options__resume {
    margin-right: 6px;
  }
  .paste-options__pause {
    margin-right: 6px;
  }
  .paste-options__checkmark {
    //transform: scale(1.2);
    filter: brightness(1);
  }
</style>
