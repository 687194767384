import {
  child,
  connectDatabaseEmulator,
  get,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from 'firebase/database'
import {
  connectFunctionsEmulator,
  type Functions,
  getFunctions,
  httpsCallable,
  type HttpsCallableResult,
} from 'firebase/functions'
import { deleteObject, getStorage, ref as storageRef } from 'firebase/storage'
import nanoId, { nanoIdSecure } from '/@/utils/generateNanoId'
import devlog from '/@/utils/log'
import { PastaLocalStorageKeys } from '../../../../types'
import { getDeviceName } from '/@/utils/devices/getDeviceName'
import {
  Device,
  Paste,
  QRLoginData,
  QRLoginStrings,
  Rooms,
} from '/@/interfaces'
import {
  idbService,
  ipcService,
  pasteActionsService,
  pwaService,
  sealdService,
} from '/@/services/index'
import { getSettings } from '/@/utils/settings/getSettings'
import isDesktop from '/@/utils/isDesktop'
import { getRandomColor } from '/@/utils/getRandomColor'
import { getAuth } from 'firebase/auth'
import { getFileType } from '/@/utils/files/getFileType'
import delay from '/@/utils/delay'
import { useRoomsStore } from '/@/store/rooms'
import { useUserStore } from '/@/store/user'
import { useAppStateStore } from '/@/store/appState'
import { getApp } from 'firebase/app'
import { useDeviceStore } from '/@/store/deviceStore'
import endpoints from '/@/config/endpoints'
import getFileURL from '/@/utils/firebase/getFileURL'
import firebaseConfig from '/@/config/firebaseConfig'
import loginService from '/@/services/loginService'
import { Unsubscribe } from '@firebase/database'

interface Database {
  app: any
  type: any
}

enum FunctionNames {
  CREATE_USER = 'createUserFunction',
  GET_JWT = 'createSealdJWT',
  CREATE_SHARE_LINK = 'createShareLink',
  GET_QR_LOGIN_TOKEN = 'createQRLoginToken',
  STORE_QR_LOGIN_TOKEN = 'storeQRLoginToken',
  GET_QR_LOGIN_DATA = 'getQRLoginData',
  DELETE_QR_LOGIN_DATA = 'deleteQRLoginData',
}

class databaseService {
  db: Database | undefined
  roomsSetUp = false
  functions: Functions | undefined
  existingQrLoginToken: string | undefined
  DBQRChangeListener: Unsubscribe | undefined

  get defaultRoomName() {
    const auth = getAuth()
    const userStore = useUserStore()
    return `${auth.currentUser?.displayName || userStore.userName}'s board`
  }

  init() {
    if (this.db && this.functions) return
    const app = getApp()
    this.db = getDatabase(app, firebaseConfig.databaseURL)
    this.functions = getFunctions(app, 'europe-west1')
    if (import.meta.env.VITE_DEV_EMULATORS) {
      // Emulator
      connectFunctionsEmulator(this.functions, '127.0.0.1', 5001)
      connectDatabaseEmulator(this.db, '127.0.0.1', 9000)
      // Emulator
    }
  }

  async getUser() {
    this.init()
    if (!this.db) return
    const dbRef = ref(this.db)
    try {
      const userStore = useUserStore()
      const user = await get(child(dbRef, `users/${userStore.userId}`))
      if (user.exists()) {
        devlog('init', 'Firebase', 'Got User', user.val())
        return user.val()
      }
    } catch (error) {
      console.error(error)
    }
  }

  async getJWT() {
    this.init()
    if (!this.functions) return
    const auth = getAuth()
    try {
      const getJWTFunction = httpsCallable(
        this.functions,
        FunctionNames.GET_JWT,
      )
      const request = (await getJWTFunction({
        userId: auth.currentUser?.uid,
      })) as HttpsCallableResult<{
        jwt: string
        status?: number
        email?: string
        error?: string
      }>
      // if (request.status !== 200) new Error('Error getting JWT')
      const { jwt } = request.data
      devlog('init', 'Firebase', 'Got user JWT')
      return jwt
    } catch (error) {
      console.error(error)
    }
  }

  async transmitLogMeInSession(path: string, key: string) {
    devlog('service', 'Firebase', 'Generating QR Login')
    this.init()
    if (!this.functions || !this.db) return
    try {
      const serializedId = await sealdService.exportIdentity()
      const getFirebaseCustomLoginToken = httpsCallable(
        this.functions,
        FunctionNames.GET_QR_LOGIN_TOKEN,
      )
      const response =
        (await getFirebaseCustomLoginToken()) as HttpsCallableResult<{
          qrToken: string
        }>
      const { qrToken: fireBaseToken } = response.data

      const qrDataString = JSON.stringify({
        t: fireBaseToken,
        s: JSON.stringify(serializedId),
      })

      const encryptedQrData = (await sealdService.encryptMessage(
        qrDataString,
      )) as string
      const symEncKeyId = (await sealdService.addSymEncKeyToSession(
        key,
      )) as string

      const encryptionInfo = {
        sessionId: sealdService.session?.sessionId as string,
        symEncKeyId,
      }

      const finalData: QRLoginData = {
        qrData: encryptedQrData,
        encryptionInfo,
      }

      const listenedOnLogMeInRef = ref(this.db, `qrLogins/${path}`)
      await set(listenedOnLogMeInRef, finalData)
    } catch (error) {
      console.error(error)
    }
  }

  async generateQrLoginToken() {
    if (this.existingQrLoginToken) return this.existingQrLoginToken
    devlog('service', 'Firebase', 'Generating QR Login')
    this.init()
    if (!this.functions) return
    try {
      const key = nanoIdSecure()
      const serializedId = await sealdService.exportIdentity()
      const generateQrLoginToken = httpsCallable(
        this.functions,
        FunctionNames.GET_QR_LOGIN_TOKEN,
      )
      const response = (await generateQrLoginToken()) as HttpsCallableResult<{
        qrToken: string
      }>
      const { qrToken } = response.data

      ////

      const qrDataString = JSON.stringify({
        t: qrToken,
        s: JSON.stringify(serializedId),
      })

      const encryptedQrData = await sealdService.encryptMessage(qrDataString)
      const symEncKeyId = await sealdService.addSymEncKeyToSession(key)

      const encryptionInfo = {
        sessionId: sealdService.session?.sessionId,
        symEncKeyId,
      }

      const storeQrLoginToken = httpsCallable(
        this.functions,
        FunctionNames.STORE_QR_LOGIN_TOKEN,
      )
      const storeResponse = (await storeQrLoginToken({
        qrData: encryptedQrData,
        encryptionInfo,
      })) as HttpsCallableResult<{ path: string }>

      const { path } = storeResponse.data

      this.existingQrLoginToken = `${path}${QRLoginStrings.KeyPathSeparator}${key}`
      return this.existingQrLoginToken
    } catch (error) {
      console.error(error)
    }
  }

  async getQrLoginData(pathId: string) {
    this.init()
    if (!this.functions) throw new Error('Functions not initialized!')
    try {
      const getQRLoginDataFunction = httpsCallable(
        this.functions,
        FunctionNames.GET_QR_LOGIN_DATA,
      )
      const request = (await getQRLoginDataFunction({
        pathId,
      })) as HttpsCallableResult<{
        qrLoginData: {
          qrData: string
          encryptionInfo: {
            sessionId: string
            symEncKeyId: string
          }
        }
      }>
      const { qrLoginData } = request.data
      return qrLoginData
    } catch (error) {
      console.error(error)
    }
  }

  async deleteQrLoginData(pathId: string) {
    this.init()
    if (!this.functions) throw new Error('Functions not initialized!')
    try {
      const deleteQRLoginDataFunction = httpsCallable(
        this.functions,
        FunctionNames.DELETE_QR_LOGIN_DATA,
      )
      await deleteQRLoginDataFunction({
        pathId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async createUser() {
    this.init()
    if (!this.functions) throw new Error('Functions not initialized!')
    try {
      const createUserFunction = httpsCallable(
        this.functions,
        FunctionNames.CREATE_USER,
      )
      const dbKey = sealdService.generateDBKey()
      const request = (await createUserFunction({
        dbKey,
      })) as unknown as any
      if (request.status !== 200) new Error('Error creating user')
      devlog('init', 'Firebase', 'Created new user', request.data)
    } catch (error) {
      console.error(error)
    }
  }

  async updateUser(parameters: { [key: string]: any } | null = null) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await update(ref(this.db, `users/${userStore.userId}`), {
        settings: getSettings(),
        updatedAt: new Date(),
        ...parameters,
      })
      devlog('service', 'Firebase', 'Updated User')
    } catch (error) {
      console.error(error)
    }
  }

  async addDevice(device: Device) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      const deviceRef = ref(this.db, `users/${userStore.userId}/devices`)
      const addedDevice = await push(deviceRef)
      await set(addedDevice, {
        ...device,
        id: addedDevice.key,
      })
      devlog('service', 'Firebase', 'Added device')
      return addedDevice
    } catch (error) {
      console.error(error)
    }
  }

  async editDevice(device: Device) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await update(
        ref(this.db, `users/${userStore.userId}/devices/${device.id}`),
        {
          ...device,
        },
      )
      devlog('service', 'Firebase', 'Updated device')
    } catch (error) {
      console.error(error)
    }
  }

  async removeDevice(device: Device) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await remove(
        ref(this.db, `users/${userStore.userId}/devices/${device.id}`),
      )
      devlog('service', 'Firebase', 'Removed device')
    } catch (error) {
      console.error(error)
    }
  }

  // Unused
  async removeUser() {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      devlog('service', 'Firebase', 'Removing User')
      await remove(ref(this.db, `users/${userStore.userId}/`))
      await remove(ref(this.db, `rooms/${userStore.userId}/`))
      devlog('service', 'Firebase', 'Removed User')
    } catch (error) {
      console.error(error)
    }
  }

  async createPaste(
    content: string,
    type: string,
    overwritePasteData: Partial<Paste> = {},
  ) {
    const roomsStore = useRoomsStore()
    const userStore = useUserStore()
    const deviceStore = useDeviceStore()
    const currentRoomId =
      roomsStore.currentRoomId ||
      localStorage.getItem(PastaLocalStorageKeys.CURRENT_ROOM_ID)
    if (!this.db) return
    const encryptedContent = await sealdService.encryptMessage(content)
    const id = overwritePasteData.id ? overwritePasteData.id : nanoId()
    devlog('app', 'Paste', 'Creating new Paste')
    try {
      await push(
        ref(this.db, `rooms/${userStore.userId}/${currentRoomId}/pastes`),
        {
          id,
          content: encryptedContent,
          contentType: type || 'text/string',
          device: getDeviceName(),
          deviceId: deviceStore.currentDeviceId,
          roomId: roomsStore.currentRoomId,
          fcmId: deviceStore.currentFcmId || '',
          userId: userStore.userId,
          createdAt: new Date().toISOString(),
          ...overwritePasteData,
        },
      )
    } catch (error) {
      console.error(error)
    }
  }

  async deletePaste(paste: Paste) {
    if (!this.db) return

    try {
      // Delete file from storage if it is a file
      if (
        getFileType(paste.contentType) !== 'string' &&
        paste.content.split('/').length === 4
      ) {
        const app = getApp()
        const storage = getStorage(app)
        const fileRef = storageRef(storage, paste.content)
        await deleteObject(fileRef)
        devlog('service', 'Firebase', 'Deleted Paste File', paste.id)
      }
    } catch (error) {
      console.error(error)
    }

    await delay(80)

    try {
      // Delete paste reference in RTDB
      await remove(
        ref(
          this.db,
          `rooms/${paste.userId}/${paste.roomId}/pastes/${paste.rtdbId}`,
        ),
      )
      devlog('service', 'Firebase', 'Deleted Paste', paste.id)
    } catch (error) {
      console.error(error)
    }
  }

  async setupRooms(rooms: Rooms) {
    const appState = useAppStateStore()
    if (appState.isLoggingOut) return
    const roomsStore = useRoomsStore()
    const roomIds = Object.keys(rooms)

    try {
      if (roomIds?.length) {
        devlog('service', 'Firebase', 'Got Rooms', rooms)
        roomsStore.rooms = rooms
        appState.isConnected = true
        pwaService.pwaReceiveShare()

        // START - Maybe move this to startupService?
        const currentRoomIdLocal = localStorage.getItem(
          PastaLocalStorageKeys.CURRENT_ROOM_ID,
        )
        const currentRoomIdLocalIsInStore = roomIds.includes(
          currentRoomIdLocal || '',
        )
        if (currentRoomIdLocal && currentRoomIdLocalIsInStore) {
          roomsStore.setActiveRoom(currentRoomIdLocal)
        } else {
          roomsStore.setActiveRoom(roomIds[0])
        }
        // END - Maybe move this to startupService?

        if (isDesktop) ipcService.updateTrayMenuConnectedStatus('connected')
        if (appState.isOfflineMode) return
        appState.loadingPastes = false
        await idbService.storePastaData(rooms)
      } else {
        console.error('Something went wrong here, there are no rooms?!')
      }
    } catch (error) {
      console.error(error)
    }
    this.roomsSetUp = true
  }

  async createRoom(name: string, settings: { [key: string]: string } = {}) {
    const appState = useAppStateStore()
    if (!this.db || appState.isLoggingOut) return
    const roomsStore = useRoomsStore()
    const userStore = useUserStore()
    try {
      const key = push(ref(this.db, `rooms/${userStore.userId}/`)).key
      await update(ref(this.db, `rooms/${userStore.userId}/${key}`), {
        id: key,
        name: name,
        settings: { color: getRandomColor(), ...settings },
        createdAt: new Date(),
        updatedAt: new Date(),
      })
      if (!key) return
      roomsStore.setActiveRoom(key)
      localStorage.setItem(PastaLocalStorageKeys.CURRENT_ROOM_ID, key)
      devlog('service', 'Firebase', 'Created Room', name)
    } catch (error) {
      console.error(error)
    }
  }

  async updateRoom(
    id: string,
    name: string,
    settings: { [key: string]: string },
  ) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await update(ref(this.db, `rooms/${userStore.userId}/${id}`), {
        id,
        name,
        settings,
        updatedAt: new Date(),
      })
      devlog('service', 'Firebase', 'Updated Room', name)
    } catch (error) {
      console.error(error)
    }
  }

  async deleteRoom(id: string) {
    if (!this.db) return
    const roomsStore = useRoomsStore()
    const userStore = useUserStore()
    try {
      await remove(ref(this.db, `rooms/${userStore.userId}/${id}`))
      devlog('service', 'Firebase', 'Deleted Room', id)
    } catch (error) {
      console.error(error)
    }
    roomsStore.currentRoomId = Object.keys(roomsStore.rooms)?.[0]
  }

  // This is where incoming pastes are handled
  async listenForRoomChanges() {
    if (!this.db) return
    const roomsStore = useRoomsStore()
    const userStore = useUserStore()
    const appState = useAppStateStore()
    const roomsRef = ref(this.db, `rooms/${userStore.userId}`)
    onValue(roomsRef, async (snapshot) => {
      if (appState.isLoggingOut) return
      const data = snapshot.val()
      if (!data) {
        // Creates room if there aren't any rooms for current user.
        await this.createRoom(this.defaultRoomName as string)
        // Create first example paste.
        await this.createPaste(
          "Welcome to Pasta! Here's a paste",
          'text/string',
          { deviceId: 'NULL_ID' },
        )
      } else if (!this.roomsSetUp) {
        await this.setupRooms(data)
      } else {
        roomsStore.rooms = data
        await pasteActionsService.handleIncomingPaste(data)
        await idbService.storePastaData(data)
      }
    })
  }

  async listenForUserChanges() {
    if (!this.db) return
    const userStore = useUserStore()
    const appState = useAppStateStore()
    const userRef = ref(this.db, `users/${userStore.userId}`)
    onValue(userRef, async (snapshot) => {
      if (appState.isLoggingOut) return
      const data = snapshot.val()
      if (!data?.email) return
      devlog('init', 'Firebase', 'User was updated', data)
      userStore.user = data
    })
  }

  public async createShareLink(paste: Paste): Promise<string | undefined> {
    this.init()
    if (!this.functions) return
    const userStore = useUserStore()
    const key = sealdService.generateShareKey()
    const isText = paste.contentType.startsWith('text/')

    let symEncKeyId

    try {
      symEncKeyId = await sealdService.addSymEncKeyToSession(key)

      const createShareLinkFunction = httpsCallable(
        this.functions,
        FunctionNames.CREATE_SHARE_LINK,
      )

      // If it's a file/image, we encrypt the public URL gotten from here
      // and replace content with it.
      if (!isText) {
        const url = await getFileURL(paste.content)
        const encryptedUrl = await sealdService.encryptMessage(url)
        if (!encryptedUrl) {
          console.error('Error encrypting file URL')
          return
        }
        paste.content = encryptedUrl
      }

      const desiredPasteData = {
        id: paste.id,
        content: paste.content,
        contentType: paste.contentType,
        createdAt: paste.createdAt,
        device: paste.device,
        userName: userStore.userName,
        encryptionInfo: {
          sessionId: sealdService.session?.sessionId,
          symEncKeyId,
        },
        filename: paste.filename ? paste.filename : '',
      }

      const request = (await createShareLinkFunction({
        ...desiredPasteData,
      })) as HttpsCallableResult<{ shareId: string }>

      devlog('service', 'Firebase', 'Created share link', request.data.shareId)

      return `${endpoints.share}${request.data.shareId}/#${key}`
    } catch (error) {
      console.error(error)
      if (symEncKeyId) {
        await sealdService.deleteSymEncKeyFromSession(symEncKeyId)
      }
    }
  }

  // Handles the desktop QR login flow (display QR on desktop to scan with
  // mobile to log in)
  listenForLogMeInQRLoginStateChanges(pathId: string, key: string) {
    this.init()
    if (!this.db) return
    const userRef = ref(this.db, `qrLogins/${pathId}`)
    this.DBQRChangeListener = onValue(userRef, async (snapshot) => {
      const data = snapshot.val() as QRLoginData
      if (data === null) return
      if (!data.qrData || !data.encryptionInfo) {
        return
      }
      await loginService.executeLogMeInQR(data, key, pathId)
      // After success unsubscribe from listener
      this.DBQRChangeListener?.()
      this.DBQRChangeListener = undefined
    })
  }

  async testFunction(functionName: string) {
    devlog('service', 'Firebase', 'Testing function: ' + functionName)
    this.init()
    if (!this.functions) return
    const testFunction = httpsCallable(this.functions, functionName)
    const response = await testFunction()
    console.log(response)
  }

  async testMethod(arg: any) {
    // return this.getJWT()
    return await sealdService.importIdentity(arg)
  }
}

export default new databaseService()
