import { downloadBlob } from '../utils/files/saveFile'
import getFileURL from '../utils/firebase/getFileURL'
import getFilename from '../utils/files/getFilename'
import { Paste } from '../interfaces'
import { firebaseDownloader } from '../utils/firebase/firebaseDownloader'
import devlog from '../utils/log'
import { idbService, ipcService, sealdService } from './index'
import { PastaLocalStorageKeys } from '../../../../types'
import { usePasteListStore } from '/@/store/pasteList'
import { useAppStateStore } from '/@/store/appState'

class DownloadService {
  paste: Paste | null = null
  pasteId = ''
  loading = false
  chunks: any[] = []
  controller: any = null
  received = 0
  response: any = null
  fileName = ''
  contentType = ''

  public async downloadFile(paste: Paste, pasteId: string): Promise<void> {
    if (this.loading) return
    this.resetLocals()
    if (sealdService.isPasteEncrypted(paste)) {
      paste.content = <string>await sealdService.decryptMessage(paste.content)
    }
    this.fileName = getFilename(paste.content)
    this.contentType = paste.contentType
    this.paste = paste
    this.pasteId = pasteId
    const fileUrl = await getFileURL(paste.content)
    return await this.transferFile(fileUrl)
  }

  async transferFile(url: string): Promise<void> {
    const signal = this.controller.signal
    this.loading = true

    try {
      this.response = await fetch(url, { signal })
      if (this.response.status >= 200 && this.response.status < 300) {
        await this.readStream(this.response)
      }
    } catch (error) {
      throw new Error(error as string | undefined)
    } finally {
      this.loading = false
    }
  }

  async readStream(response: Response): Promise<void> {
    if (!response) return
    const pasteListStore = usePasteListStore()
    const reader = response.body?.getReader()
    const length = response.headers.get('content-length')

    // Loop through the response stream and extract data chunks
    while (this.loading) {
      const { done, value } = (await reader?.read()) as any
      const payload = {
        received: this.received,
        length,
        loading: this.loading,
      }

      if (done) {
        this.loading = false
      } else {
        // Push values to the chunk array
        if (!payload.length) return
        this.chunks.push(value)
        this.received += value.length
        // @ts-ignore
        const progress = ((payload.received / payload.length) * 100).toFixed(2)
        pasteListStore.updateDownloadingPasteStatus(this.pasteId, {
          isDownloading: true,
          downloadAmount: progress,
          decrypting: false,
        })
        console.info(`Download progress: ${progress}%`)
      }
    }

    // Concat the chunks into a single array
    const body = new Uint8Array(this.received)
    let position = 0

    for (const chunk of this.chunks) {
      body.set(chunk, position)
      position += chunk.length
    }

    // Decode the response and download it
    const blob = new Blob([body], { type: this.contentType })
    try {
      const decrypted = await sealdService.decryptFile(blob, this.pasteId)
      if (!decrypted) return
      downloadBlob(decrypted, this.fileName)
    } catch (err) {
      console.error(err)
    }
    pasteListStore.removePasteDownloadStatus(this.pasteId)
    this.resetLocals()
  }

  resetLocals(): void {
    this.paste = null
    this.pasteId = ''
    this.loading = false
    this.chunks = []
    this.received = 0
    this.controller = new AbortController()
    this.fileName = ''
    this.contentType = ''
  }

  cancel(): void {
    this.resetLocals()
    this.controller.abort()
  }
}

export default DownloadService
