import { useFavicon } from '@vueuse/core'

const icon = useFavicon()

const showFaviconNotification = (): void => {
  icon.value = '/favicon-notification.ico'
}

const hideFaviconNotification = (): void => {
  icon.value = '/favicon.ico'
}

export { showFaviconNotification, hideFaviconNotification }
