import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Room } from '/@/interfaces'
import { hideFaviconNotification } from '/@/utils/toggleFaviconNotification'

interface Rooms {
  [key: string]: Room
}

export const useRoomsStore = defineStore('rooms', () => {
  const rooms = ref<Rooms>({})
  const currentRoomId = ref('')
  const roomsWithUnseenPastes = ref<string[]>([])
  const showRoomSettings = ref(false)
  const showCreateRoom = ref(false)

  const currentRoom = computed(() => rooms.value[currentRoomId.value])
  const currentRoomHasUnseenPastes = computed(() => {
    return roomsWithUnseenPastes.value?.includes(currentRoomId.value)
  })

  const setActiveRoom = (roomId: string) => {
    currentRoomId.value = roomId
    localStorage.setItem('pasta:currentRoomId', roomId)
  }

  const addRoomToUnseenPastes = (roomId: string) => {
    roomsWithUnseenPastes.value.push(roomId)
  }

  const removeRoomFromUnseenPaste = (roomId: string) => {
    if (roomsWithUnseenPastes.value.includes(roomId)) {
      roomsWithUnseenPastes.value = roomsWithUnseenPastes.value.filter(
        (item) => item !== roomId
      )
      hideFaviconNotification()
    }
  }

  return {
    rooms,
    currentRoomId,
    currentRoom,
    roomsWithUnseenPastes,
    showRoomSettings,
    showCreateRoom,
    currentRoomHasUnseenPastes,
    setActiveRoom,
    addRoomToUnseenPastes,
    removeRoomFromUnseenPaste,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRoomsStore, import.meta.hot))
}
