import {
  PastaDesktopSetting,
  PastaLocalStorageKeys,
  PastaTrayMenuIds,
} from '../../../../../types'
import { useAppStateStore } from '/@/store/appState'

const getSettings = () => {
  const appState = useAppStateStore()
  return {
    showNotifications: appState.showNotifications,
    clickToCopy: appState.clickToCopy,
    devLogging: appState.devLogging,
    allowClipboardAccess: appState.allowClipboardAccess,
    autoClipboardRead: appState.autoClipboardRead,
    showPastePreview: appState.showPastePreview,
  }
}

const getDesktopSettings = (): PastaDesktopSetting[] => {
  const appState = useAppStateStore()
  return [
    {
      localStorageString: PastaLocalStorageKeys.COPY_AUTO_SEND,
      menuItemString: PastaTrayMenuIds.COPY_AUTO_SEND,
      storeName: 'copyAutoSend',
      defaultValue: false,
      value: appState.copyAutoSend,
    },
    {
      localStorageString: PastaLocalStorageKeys.CLIPBOARD_AUTO_RECEIVE,
      menuItemString: PastaTrayMenuIds.CLIPBOARD_AUTO_RECEIVE,
      storeName: 'clipboardAutoReceive',
      defaultValue: true,
      value: appState.clipboardAutoReceive,
    },
    {
      localStorageString: PastaLocalStorageKeys.AUTO_DOWNLOAD,
      menuItemString: PastaTrayMenuIds.AUTO_DOWNLOAD,
      storeName: 'autoDownload',
      defaultValue: false,
      value: appState.autoDownload,
    },
    {
      localStorageString: PastaLocalStorageKeys.AUTO_DOWNLOAD_LOCATION,
      storeName: 'autoDownloadLocation',
      defaultValue: '',
      value: appState.autoDownloadLocation,
    },
  ]
}

export { getSettings, getDesktopSettings }
