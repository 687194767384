const devConfig = {
  appId: 'fab89142-9aa8-47b8-b591-17da00b54bc7',
  apiUrl: 'https://api.staging-0.seald.io/',
  keyStorageURL: 'https://ssks.staging-0.seald.io/',
}

const prodConfig = {
  appId: 'fab89142-9aa8-47b8-b591-17da00b54bc7',
  apiUrl: 'https://api.staging-0.seald.io/',
  keyStorageURL: 'https://ssks.staging-0.seald.io/',
}

export default devConfig
