// @ts-ignore -- import not resolving for some reason
import AnonymousSDKBuilder from '@seald-io/sdk/anonymous-browser'
import {
  AnonymousSDK,
  AnonymousEncryptionSession,
} from '@seald-io/sdk/lib/anonymous'
import sealdConfig from '../config/sealdConfig'

class SealdServiceAnonymous {
  public seald: null | AnonymousSDK = null
  public session: null | AnonymousEncryptionSession = null

  async init() {
    if (this.seald) return
    this.seald = AnonymousSDKBuilder({
      appId: sealdConfig.appId,
      apiURL: sealdConfig.apiUrl,
    })
  }

  async waitForSession() {
    let count = 0
    if (this.session) return this.session
    return new Promise((resolve, reject) => {
      const wait = () => {
        if (this.session) return resolve(this.session)
        count++
        if (count > 10000) reject()
        setTimeout(wait, 80)
      }
      wait()
    })
  }

  async decryptMessage(text: string) {
    await this.waitForSession()
    try {
      // Hacky workaround for already decrypted messages
      const json = JSON.parse(text)
      if (json.sessionId) return this.session?.decryptMessage(text)
    } catch (e) {
      console.error(e)
      return text
    }
  }

  async setEncryptionSession(
    jwt: string,
    sessionId: string,
    symEncKeyId: string,
    password: string,
  ) {
    if (!this.seald) {
      console.warn('Seald not initialized')
      return
    }
    this.session = await this.seald.retrieveEncryptionSession({
      retrieveSessionToken: jwt,
      sessionId,
      symEncKeyId,
      symEncKeyPassword: password,
      appId: sealdConfig.appId,
    })
  }

  destroy() {
    this.session = null
    this.seald = null
  }
}

export default new SealdServiceAnonymous()
