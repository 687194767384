<template>
  <Settings :width="600" :height="600" :close-setting="closeShareQR">
    <template #categories>
      <SettingsCategoryButton :selected="true"
        >Log in With QR Code
      </SettingsCategoryButton>
    </template>
    <template #settings>
      <div class="qr-container">
        <LoadingSpinner v-if="!qrValue" :color="'#fff'" :size="44" />
        <h3 class="qr-container__loading-msg" v-if="!qrValue">
          {{ loadingMessage }}
        </h3>
        <qrcode-vue
          v-if="qrValue"
          class="qr-code"
          :value="qrValue"
          :size="400"
          :foreground="'#fff'"
          :background="'#ffffff00'"
          :margin="qrMargin"
          level="H"
        />
      </div>
    </template>
    <template #footer>
      <div class="pasta-share-footer">
        <span>Scan this QR code on another device to log in.</span>
      </div>
    </template>
  </Settings>
</template>

<script setup lang="ts">
  import { computed, onBeforeMount, ref } from 'vue'
  import QrcodeVue from 'qrcode.vue'
  import isMobile from '../../../utils/isMobile'
  import { databaseService } from '../../../services'
  import { useAppStateStore } from '/@/store/appState'

  // Dependencies

  const appState = useAppStateStore()

  // Data

  const qrValue = ref()
  const loadingMessage = ref('Generating Link...')

  // Methods

  const closeShareQR = () => {
    appState.showLoginQR = false
  }

  // Computed

  const qrMargin = computed(() => (isMobile.value ? 4 : 0))

  // Hooks

  onBeforeMount(async () => {
    qrValue.value = await databaseService.generateQrLoginToken()
  })
</script>

<style lang="scss" scoped>
  .close-button {
    margin-left: auto;
  }

  .pasta-share-footer {
    width: 100%;
    display: flex;
    align-items: center;
    //justify-content: flex-start;
    gap: 16px;
    text-align: center;
    p {
    }
  }

  .qr-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .qr-code {
    margin: 0 auto;
  }

  .qr-share-link {
    color: $accent-primary;
    text-align: center;
  }
</style>
