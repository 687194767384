<template>
  <div>
    <div class="room" :style="[settingsIsOpen ? roomBorder : '']">
      <div
        class="room__container"
        :style="[isActiveRoom ? activeRoom : '']"
        @mousedown="openRoom(roomId)"
      >
        <div class="room__color-text">
          <div class="room-color" :style="roomColor">
            <div
              v-show="showNotification"
              class="room-color--notification"
            ></div>
          </div>
          <p class="truncate">{{ roomName }}</p>
        </div>
        <span v-show="isActiveRoom" class="room-options">
          <i-feather-tool
            v-show="!settingsIsOpen"
            class="room-options__info"
            alt="Settings"
            @mousedown="openSettings()"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, reactive, toRefs } from 'vue'
  import { initAutoRead } from '/@/utils/AutoRead'
  import { getHashColor } from '/@/utils/getRandomColor'
  import { touchService } from '../../services'
  import delay from '../../utils/delay'
  import isMobile from '../../utils/isMobile'
  import { hideFaviconNotification } from '/@/utils/toggleFaviconNotification'
  import { useRoomsStore } from '/@/store/rooms'
  import { useAppStateStore } from '/@/store/appState'

  export default defineComponent({
    name: 'Room',
    props: {
      roomName: {
        type: String,
        required: true,
        default: '',
      },
      roomId: {
        type: String,
        required: true,
        default: '',
      },
      roomSettings: {
        type: Object,
        required: false,
        default: null,
      },
    },
    setup(props) {
      const roomsStore = useRoomsStore()
      const appState = useAppStateStore()

      const methods = {
        openSettings() {
          roomsStore.showRoomSettings = true
        },
        async openRoom(roomId: string) {
          if (roomId === roomsStore.currentRoomId) return
          roomsStore.setActiveRoom(roomId)
          roomsStore.removeRoomFromUnseenPaste(roomId)
          hideFaviconNotification()
          if (!isMobile.value) return
          await delay(150)
          await touchService.slideElement(0, appState.mainElement)
          initAutoRead()
        },
      }

      const data = reactive({
        roomBorder: {
          borderBottom: 'rgba(255, 255, 255, 0.12)',
        },
        activeRoom: {
          backgroundColor: '#4c4c4c',
        },
        settingsIsOpen: false,
      })

      const compute = {
        isActiveRoom: computed(() => props.roomId === roomsStore.currentRoomId),
        showNotificationIcon: computed(() => props.roomId),
        showNotification: computed(() =>
          roomsStore.roomsWithUnseenPastes.includes(props.roomId)
        ),
        roomColor: computed(() => {
          return {
            backgroundColor:
              props.roomSettings?.color || getHashColor(props.roomId),
          }
        }),
      }

      return {
        ...compute,
        ...methods,
        ...toRefs(data),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .room {
    display: flex;
    align-items: center;
    min-height: min-content;
    width: 100%;
    overflow: hidden;
    margin: 10px 0;
    cursor: pointer;
    touch-action: manipulation;

    p {
      font-size: 16px;
      font-weight: bold;
      padding-right: 5px;
      max-width: 83%;
    }

    &:hover {
      color: $text-secondary;
    }

    .room-color {
      min-height: 24px;
      min-width: 24px;
      border-radius: 100px;
      margin-right: 8px;
      transition: background-color 250ms ease-in-out;

      .room-color--notification {
        height: 8px;
        width: 8px;
        background-color: $alert;
        border-radius: 50px;
        margin-left: auto;
      }
    }

    &__color-text {
      display: flex;
      width: calc(100% - 24px);
      align-items: center;
    }

    &__container {
      display: flex;
      width: 100%;
      padding: 10px 10px;
      border-radius: 50px;
      transition: background-color 100ms ease-in-out;
    }

    .generic-button {
      margin: 0 8px 0 auto;
    }

    span.room-options {
      display: grid;
      place-content: center;
      transition: width 100ms ease-in-out;

      .pasta-icon {
        cursor: pointer;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
</style>
