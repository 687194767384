import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoomsStore } from '/@/store/rooms'
import { Paste, PasteDownloadStatus, PasteUploadStatus } from '/@/interfaces'

export const usePasteListStore = defineStore('pasteList', () => {
  const roomsStore = useRoomsStore()
  const uploadingPastes = ref<Paste[]>([])

  const pasteList = computed(() => {
    const currentRoomId = roomsStore.currentRoomId
    const currentRoomPastes = roomsStore.currentRoom?.pastes
    if (currentRoomPastes) {
      // Kinda hacky but we have to apply the RTDB ID inside the paste, so we
      // can access the paste path while deleting it.
      const pastesWithIds = Object.entries(currentRoomPastes).map(
        (value: [string, unknown]) => {
          ;(value[1] as Paste).rtdbId = value[0]
          return value[1]
        },
      )
      const set = new Set()
      return (
        [
          ...(pastesWithIds as Paste[]),
          ...uploadingPastes.value.filter(
            (paste: Paste) => paste.roomId === currentRoomId,
          ),
        ]
          // @ts-ignore
          .filter((o) => !set.has(o['id']) && set.add(o['id'])) // Dedupe
          // @ts-ignore
          .sort((objA, objB) => Number(objA.createdAt) - Number(objB.createdAt))
          .reverse()
      ) // Reverse because the .paste-list is flipped in css
    } else {
      return []
    }
  })

  const isPasteListEmpty = computed(() => pasteList.value.length === 0)

  const updateUploadingPasteStatus = (
    id: string,
    uploadStatus: PasteUploadStatus,
  ) => {
    const uploadingPasteToUpdate = uploadingPastes.value.find(
      (paste) => paste.id === id,
    )
    if (!uploadingPasteToUpdate) return
    const index = uploadingPastes.value.indexOf(uploadingPasteToUpdate)
    uploadingPasteToUpdate.uploadStatus = uploadStatus
    uploadingPastes.value.splice(index, 1, uploadingPasteToUpdate)
  }

  const updateEncryptingPasteStatus = (id: string, cryptAmount: number) => {
    const uploadingPasteToUpdate = uploadingPastes.value.find(
      (paste) => paste.id === id,
    )
    if (!uploadingPasteToUpdate) return
    const index = uploadingPastes.value.indexOf(uploadingPasteToUpdate)
    if (uploadingPasteToUpdate.uploadStatus) {
      uploadingPasteToUpdate.uploadStatus.cryptAmount = cryptAmount
    } else {
      return
    }
    uploadingPastes.value.splice(index, 1, uploadingPasteToUpdate)
  }

  const updateDecryptingPasteStatus = (id: string, cryptAmount: number) => {
    const downloadingPasteToUpdate = pasteList.value.find(
      (paste) => paste.id === id,
    )
    if (!downloadingPasteToUpdate || !downloadingPasteToUpdate.downloadStatus)
      return
    downloadingPasteToUpdate.downloadStatus.cryptAmount = cryptAmount
    downloadingPasteToUpdate.downloadStatus.decrypting = cryptAmount < 100
    downloadingPasteToUpdate.downloadStatus.isDownloading = false
    console.log(downloadingPasteToUpdate.downloadStatus)
  }

  const updateDownloadingPasteStatus = (
    id: string,
    downloadStatus: PasteDownloadStatus,
  ) => {
    const downloadingPasteToUpdate = pasteList.value.find(
      (paste) => paste.id === id,
    )
    if (!downloadingPasteToUpdate) return
    downloadingPasteToUpdate.downloadStatus = downloadStatus
  }

  const removePasteDownloadStatus = (id: string) => {
    const downloadingPasteToUpdate = pasteList.value.find(
      (paste) => paste.id === id,
    )
    if (!downloadingPasteToUpdate) return
    downloadingPasteToUpdate.downloadStatus = undefined
  }

  const removeUploadingPaste = (id: string) => {
    const i = uploadingPastes.value.find((item: Paste) => item.id === id)
    if (!i) return
    const index = uploadingPastes.value.indexOf(i)
    uploadingPastes.value.splice(index, 1)
  }

  return {
    uploadingPastes,
    pasteList,
    isPasteListEmpty,
    updateUploadingPasteStatus,
    removeUploadingPaste,
    updateDownloadingPasteStatus,
    removePasteDownloadStatus,
    updateEncryptingPasteStatus,
    updateDecryptingPasteStatus,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePasteListStore, import.meta.hot))
}
