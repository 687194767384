<template>
  <div class="settings-category-button">
    <a :aria-current="selected">
      <h2>
        <button>
          <slot></slot>
        </button>
      </h2>
    </a>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SettingsCategoryButton',
    props: {
      selected: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  })
</script>

<style scoped lang="scss">
  .settings-category-button {
    touch-action: manipulation;
  }

  .settings-category-button:not(:last-child) {
    margin-right: 30px;
  }

  button {
    z-index: 1;
    position: relative;
    font-family: inherit;
    color: #fff;
    outline: none;
    border: none;
    background-color: #ffffff00;
    overflow: visible;
    transition: color 0.4s ease-in-out;
    font-weight: 100;
    user-select: none;
  }

  button::before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $accent-primary;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  [aria-current='true'] {
    position: relative;
  }

  [aria-current='true']::before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $accent-primary;
    transform-origin: bottom right;
    transform: scaleX(1);
    transition: transform 0.5s ease;
  }

  button:hover {
    cursor: pointer;
    color: #e0e0e0;
  }

  button:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  a {
    position: relative;
    text-decoration: none !important;
  }

  a::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #0e76de;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  h2 {
    line-height: 33px;
  }

  h2:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
</style>
