import { ipcService } from '/@/services'

const checkMacOsIsTrustedAccessibility = async () => {
  return await ipcService.returnCheckMacOsIsTrustedAccessibility(false)
}

const promptToEnableMacosTrustedAccessibility = async () => {
  alert(
    'Pasta needs accessibility permissions to enable the Command+C+C global shortcut, ' +
      'please enable it in Security & Privacy settings'
  )
  await ipcService.returnCheckMacOsIsTrustedAccessibility(true)
  return
}

export {
  checkMacOsIsTrustedAccessibility,
  promptToEnableMacosTrustedAccessibility,
}
