import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { getApp } from 'firebase/app'

const getFileURL = async (filePath: string): Promise<string> => {
  const app = getApp()
  const storage = getStorage(app)

  return await getDownloadURL(ref(storage, filePath))
}

export default getFileURL
