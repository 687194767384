<template>
  <transition name="viewFade">
    <img
      v-if="pasteImageUrl"
      ref="pasteImage"
      class="paste-image-img"
      :src="pasteImageUrl"
      alt=""
    />
  </transition>
  <div ref="pasteImageRenderTrigger" class="paste-image-render-trigger"></div>
</template>

<script setup lang="ts">
  import { computed, defineProps, ref } from 'vue'
  import devlog from '../../utils/log'
  import { firebaseDownloader } from '/@/utils/firebase/firebaseDownloader'
  import { idbService } from '../../services'
  import { useIntersectionObserver } from '@vueuse/core'
  import { Paste } from '/@/interfaces'

  // Props

  const props = defineProps<{
    paste: Paste
    pasteId: string
    onImageRendered: () => void
  }>()

  // Data

  const pasteImageRenderTrigger = ref(null)
  const generatedImageUrl = ref('')

  // Dependencies

  // Lazy load the images, so they only render when entering the viewport.
  const { stop } = useIntersectionObserver(
    pasteImageRenderTrigger,
    ([{ isIntersecting }]) => {
      if (isIntersecting) {
        renderImage()
        stop()
      }
    },
  )

  // Computed

  const pasteImageUrl = computed(() => {
    return props.paste?.image
      ? URL.createObjectURL(props.paste?.image)
      : generatedImageUrl.value
  })

  // Methods

  const renderImage = async () => {
    if (!props.paste) return
    // Gets image from IDB and renders it to canvas
    const paste = await idbService.getImagePaste(props.paste.id)
    if (paste && paste.image) {
      generatedImageUrl.value = URL.createObjectURL(paste?.image as File)
    } else {
      // If no image is found in idb, download it again, store it in IDB
      const { timeTaken, imageBlob } = await firebaseDownloader(
        props.paste.content,
        props.pasteId,
      )
      if (!imageBlob) {
        devlog('error', 'PasteImage', 'Image blob not found!')
        throw new Error('Image Blob not found!')
      }

      await idbService.storeImagePaste({
        id: props.pasteId,
        content: props.paste.content,
        contentType: props.paste.contentType,
        image: imageBlob,
        device: props.paste.device,
        deviceId: props.paste.deviceId,
        roomId: props.paste.roomId,
        userId: props.paste.userId,
        createdAt: props.paste.createdAt,
      })
      generatedImageUrl.value = URL.createObjectURL(imageBlob)
    }
    props.onImageRendered()
  }
</script>

<style lang="scss">
  .paste-image-img {
    width: 100%;
    background-color: $background-paste;
  }

  .paste-image-render-trigger {
    position: absolute;
    bottom: -25px;
  }
</style>
