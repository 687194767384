export async function base64UrlToFile(
  b64Data: string,
  contentType: string,
  fileName: string
): Promise<File> {
  const sliceSize = 512
  contentType = contentType || ''
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  return new File(byteArrays, fileName, { type: contentType })
}
