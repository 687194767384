import { useAppStateStore } from '/@/store/appState'

// TODO: refactor, this is supposed to allow users to paste directly with
//  ctrl+v when they aren't focused on the input. If they are focused on the
//  input it should not automatically send the paste when hitting ctrl+v.

const handlePasteVisibility = (el: HTMLElement | null | undefined): void => {
  const appState = useAppStateStore()
  if (el?.tagName !== 'INPUT') {
    appState.allowPaste = true
  } else if (el?.tagName === 'INPUT') {
    appState.allowPaste = false
  }
}

export default handlePasteVisibility
