import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { version } from './config/version'

window.pastaVersion = version

const tippyConfig = {
  directive: 'tippy', // => v-tippy
  component: 'tippy', // => <tippy/>
  componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
  touchHold: true,
  defaultProps: {
    allowHTML: true,
    theme: 'light',
    delay: 800,
  },
}

const pinia = createPinia()

createApp(App).use(pinia).use(VueTippy, tippyConfig).mount('#app')
