import { useRoomsStore } from '/@/store/rooms'

const makeSureRoomSelected = () => {
  const roomsStore = useRoomsStore()
  if (roomsStore.currentRoomId) return
  if (!roomsStore.rooms || Object.keys(roomsStore.rooms)?.length === 0)
    alert('There are no rooms available to share to')
  roomsStore.currentRoomId = Object.keys(roomsStore.rooms)[0]
}

export default makeSureRoomSelected
