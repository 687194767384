<template>
  <div class="paste-upload-spinner">
    <LoadingSpinner v-show="!showSuccessIcon" :color="'#fff'" :size="size" />
    <i-feather-check
      v-show="showSuccessIcon"
      class="paste-options__checkmark"
    />
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import delay from '../../../utils/delay'
  import devlog from '../../../utils/log'

  const props = withDefaults(
    defineProps<{
      uploadProgress: number
      size?: number
      successIconDelay?: number
    }>(),
    {
      size: 18,
      successIconDelay: 1200,
    },
  )

  const showSuccessIcon = ref(false)
  const showLoadingSpinner = ref(true)

  const onProgressComplete = async () => {
    showSuccessIcon.value = true
    await delay(props.successIconDelay)
    showSuccessIcon.value = false
    showLoadingSpinner.value = false
  }

  watch(
    () => props.uploadProgress,
    async (progress) => {
      devlog('app', 'Firebase Upload', 'Progress', progress)
      if (progress === 100) await onProgressComplete()
    },
  )
</script>

<style lang="scss" scoped>
  .paste-upload-spinner {
    margin-top: -9px;
    margin-right: 9px;
    &__img {
      margin-top: -4px;
      margin-right: 4px;
      margin-left: 24px;
      @media (max-width: 500px) {
        margin-left: 0px;
      }
      .paste-options__checkmark {
        // TODO: This is hidden because it breaks the layout
        display: none;
      }
    }
    .paste-options__checkmark {
      margin-top: 11px;
    }
  }
</style>
