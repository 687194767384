<template>
  <div
    class="paste-input"
    :class="{
      'paste-input--desktop': isDesktop,
      'paste-input--offline': isOfflineMode || !isConnected,
    }"
  >
    <form @submit="onPasteSubmit">
      <label for="pasteInput" />
      <div class="paste-input__container">
        <textarea
          id="pasteInput"
          ref="pasteInput"
          v-model="inputText"
          type="text"
          maxlength="8000"
          :placeholder="placeholderText"
          autoFocus
          autocomplete="off"
          :disabled="isOfflineMode || !isConnected"
          @click="onFocus"
          @blur="onBlur"
          @keydown.enter.prevent="onPasteSubmit"
        />
        <div
          ref="pasteInputColors"
          class="paste-input--paste-input-colors"
        ></div>
        <i-feather-x
          class="paste-input--clear-input"
          alt="Clear Input"
          @click="clearInput"
        />
      </div>
    </form>
    <div class="paste-input__buttons">
      <button
        aria-label="Select file to upload"
        @click="onClickFileSelect"
      ></button>
      <button
        type="submit"
        aria-label="Send Paste"
        @click="onPasteSubmit"
      ></button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import firebaseUploader from '../utils/firebase/firebaseUploader'
  import isDesktop from '/@/utils/isDesktop'
  import { databaseService } from '/@/services'
  import isOverUsageLimit from '/@/utils/files/isOverUsageLimit'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'

  const appState = useAppStateStore()
  const { isConnected, isOfflineMode } = storeToRefs(appState)
  const pasteInput = ref<null | HTMLInputElement>(null)
  const pasteInputColors = ref<null | HTMLElement>(null)
  const placeholderText = computed(() =>
    isOfflineMode.value ? 'Offline Mode' : 'Paste a copypasta'
  )

  const isFocused = ref(false)
  const uploadElement = ref<null | HTMLInputElement>(null)
  const inputText = ref('')

  const onFocus = () => {
    isFocused.value = true
    appState.pasteInputFocused = true
  }

  const onBlur = () => {
    appState.pasteInputFocused = false
  }

  const onClickFileSelect = () => {
    if (!uploadElement.value) {
      uploadElement.value = document.createElement('input')
      uploadElement.value.type = 'file'
      uploadElement.value.multiple = true
    }
    uploadElement.value.click()
    uploadElement.value.onchange = (event: Event) => {
      let files = (event.target as HTMLInputElement).files
      if (!files) return
      const filesArr = Array.from(files)

      if (isOverUsageLimit(filesArr)) {
        alert(
          'File to large! You will go over your storage usage limit, please delete some files first!'
        )
        return
      }
      filesArr.forEach((file: File) => {
        firebaseUploader(file, file.type)
      })
    }
  }

  const onPasteSubmit = async (e: InputEvent): Promise<void> => {
    let text
    e.preventDefault()
    if (!inputText.value) {
      if (!text) return
      text = await navigator.clipboard.readText()
    } else {
      text = inputText.value
    }
    await databaseService.createPaste(text, 'text/string')
    clearInput()
  }

  const clearInput = (): void => {
    if (!pasteInput.value) return
    pasteInput.value.value = ''
  }
</script>

<style lang="scss" scoped>
  .paste-input {
    padding: 2px 10px;
    display: flex;
    align-items: center;
    flex: 1;
    background-color: $background-primary;
    z-index: 2;

    &--desktop {
      padding: 8px 10px;
    }

    &--offline {
      pointer-events: none;
      opacity: 0.25;
    }

    form {
      height: 100%;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;

      input[type='text'],
      textarea {
        color: #fff;
        width: 100%;
        min-height: 43px;
        height: 42px;
        background-color: $background-input;
        outline: none;
        border-radius: 2vmax;
        margin-right: 14px;
        padding: 8px 18px 0 18px;
        border: $background-input;
        transition: transform 100ms ease-in-out, border 300ms ease-in-out;
        z-index: 1;
        white-space: pre-wrap;
        display: flex;
        resize: none;

        &:focus {
          & + .paste-input--paste-input-colors {
            opacity: 0.75;
          }
        }
      }
    }

    &__resizer {
      width: 100%;
      height: 3px;
      background-color: red;
      position: absolute;
      margin-top: -60px;
      cursor: ns-resize;
    }

    &__container {
      position: relative;
      width: 100%;
      padding-right: 10px;
      z-index: 2;
    }

    &__buttons {
      display: flex;

      button:first-child {
        margin-right: 8px;
      }

      button {
        color: #fff;
        border: none;
        border-radius: 100px;
        height: 45px;
        width: 45px;
        cursor: pointer;
        font-weight: 600;
        background-color: $accent-primary;
        background-image: url('../../assets/icons/send.svg');
        background-repeat: no-repeat;
        background-position: 11px 12px;
        background-size: 22px;

        &:first-child {
          background-image: url('../../assets/icons/file.svg');
          background-position: 0.8em 11px;
          background-size: 21px;
        }
      }
    }

    &--paste-input-colors {
      display: flex;
      width: calc(100% - 8px);
      height: 105%;
      flex: initial;
      flex-direction: row;
      border-radius: 100px;
      position: absolute;
      top: -1px;
      left: -1px;
      transition: opacity 0.5s ease 0s;
      opacity: 0;
      z-index: -1;
    }

    .paste-input--clear-input {
      height: 20px;
      width: 20px;
      position: absolute;
      cursor: pointer;
      left: 100%;
      top: 11px;
      margin-left: -40px;
      filter: brightness(0.4);

      &:hover {
        filter: opacity(1);
      }
    }
  }
</style>
