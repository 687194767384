const bufferToFile = (
  buffer: Uint8Array,
  fileName: string,
  fileType: string
) => {
  try {
    const blob = new Blob([buffer], { type: fileType })
    return new File([blob], fileName, { type: fileType })
  } catch (err) {
    console.error(err)
  }
}

export default bufferToFile
