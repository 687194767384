<template>
  <transition name="viewFade">
    <div
      v-if="userStore.storageLimits && !isNaN(usedPercent)"
      class="pasta-storage-meter"
    >
      <CircleProgress
        v-if="showRing"
        v-tippy="{
          content: `${amountStringUsed} used of ${amountStringMax}`,
          placement: 'bottom',
          touch: true,
          delay: 200,
        }"
        :class="'pasta-progress-circle'"
        :size="20"
        :fill-color="'#fff'"
        :border-width="2"
        :border-bg-width="2"
        :empty-color="'#6e6e6e'"
        :percent="usedPercent"
      />
      <progress
        v-else
        v-tippy="{
          content: `${amountStringUsed} used of ${amountStringMax}`,
          placement: 'bottom',
          touch: true,
          delay: 200,
        }"
        class="pasta-storage-meter__progress"
        max="100"
        :value="usedPercent"
      >
        {{ usedPercent }}
      </progress>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { directive as vTippy } from 'vue-tippy'
  import byteAmountConversion from '/@/utils/byteAmountConversion'
  import { useUserStore } from '/@/store/user'
  import isMobile from '/@/utils/isMobile'
  import CircleProgress from 'vue3-circle-progress'

  // Dependencies

  const userStore = useUserStore()

  // Computed

  const maxBytes = computed(() => userStore.storageLimits?.max)

  const usedBytes = computed(() => userStore.storageLimits?.used)

  const usedPercent = computed(() => {
    if (!maxBytes.value || !usedBytes.value) return 0
    const actual = parseInt(
      ((100 * usedBytes?.value) / maxBytes?.value).toFixed(2),
    )
    if (actual === 0) return 5
    if (actual <= 5)
      return 5 // Minimum 5% so the progress meter isn't a tiny square at small amounts
    else return actual
  })

  const amountStringUsed = computed(() =>
    byteAmountConversion(usedBytes.value || 0),
  )

  const amountStringMax = computed(() =>
    byteAmountConversion(maxBytes.value || 0),
  )

  const progressBarColor = computed(() =>
    usedPercent.value > 85 ? '#dc2626' : '#0b9494',
  )

  const showRing = computed(() => {
    return isMobile.value
  })
</script>

<style scoped lang="scss">
  .pasta-storage-meter {
    margin-right: 16px;
    margin-left: 12px;
    .pasta-progress-circle {
      transform: scale(0.9);
      margin-right: -11px;
    }
    &__progress {
      border: 0;
      margin-top: 12px;
      &::-webkit-progress-bar {
        background-color: #ececec;
        border-radius: 8px;
        height: 8px;
      }
      &::-webkit-progress-value {
        background-color: v-bind(progressBarColor);
        border-radius: 8px;
        height: 8px;
        transition: inline-size ease-in-out 250ms;
      }
    }
  }
</style>
