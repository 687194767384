import isDesktop from '/@/utils/isDesktop'
import { PastaIPCNames, PastaLocalStorageKeys } from '../../../../types'
import { signOut, getAuth } from 'firebase/auth'
import { sealdService } from '/@/services'
import { useAppStateStore } from '/@/store/appState'

const logout = async (): Promise<void> => {
  const appState = useAppStateStore()
  appState.isLoggingOut = true
  await signOut(getAuth())
  // Clear localstorage but retain device id in case the user returns.
  const deviceId = localStorage.getItem(PastaLocalStorageKeys.DEVICE_NAME)
  localStorage.clear()
  if (deviceId)
    localStorage.setItem(PastaLocalStorageKeys.DEVICE_NAME, deviceId)
  // Clear idb
  indexedDB.databases().then((dbs) => {
    dbs.forEach((db) => {
      if (!db.name) return
      indexedDB.deleteDatabase(db.name)
    })
  })
  // Drop local seald db
  await sealdService.seald?.dropDatabase()
  // Remove all cookies for domain
  deleteAllCookies()
  // Finally reload the page to redirect to login.
  localStorage.setItem(PastaLocalStorageKeys.HAS_LOGGED_OUT, 'true')
  if (isDesktop) {
    window.ipcRenderer.send(PastaIPCNames.relaunchApp)
    return
  }
  window.location.reload()
}

const deleteAllCookies = () => {
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

export default logout
