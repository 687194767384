import { useUserStore } from '/@/store/user'

const isOverUsageLimit = (files: File[]) => {
  const userStore = useUserStore()
  if (!userStore.storageLimits) return
  const { max, used } = userStore.storageLimits
  const totalFileSize = files.reduce((partialSum, a) => partialSum + a.size, 0)
  return used + totalFileSize > max
}

export default isOverUsageLimit
