<template>
  <transition name="viewFade">
    <PastePreview v-show="showPastePreview" />
  </transition>
  <MobilePasteActions v-if="showMobilePasteActions" />
  <Sidebar
    v-show="!isMobile || showSidebar"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchDrag"
    @touchend="handleTouchEnd"
  />
  <BetaForm
    v-if="showBetaForm"
    :on-has-account="() => (showBetaForm = false)"
  />
  <Login
    v-if="
      showAuthentication &&
      !showMaintenanceMode &&
      !showBetaForm &&
      !showQRScanner &&
      !showLogMeInQR &&
      !loggedIn
    "
  />
  <Signup v-if="showSignup && !showAuthentication" />
  <EncryptionKey v-if="showEncryptionKey" />
  <EncryptionKeyInput v-if="showEncryptionKeyInput" />
  <VerifyEmail v-if="showEmailVerification" />
  <AskToVerifyEmail v-if="showAskForEmailVerification" />
  <ResetPassword v-if="showPasswordReset" />
  <FileDrop v-if="showFileDrop" :toggle-file-drop="toggleFileDrop" />
  <LoadingOverlay v-if="showLoadingOverlay" />
  <UpdateOverlay v-if="showUpdateOverlay" />
  <main
    ref="mainElement"
    @dragover="handleDrag"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchDrag"
    @touchend="handleTouchEnd"
    @click="closeSidebar"
  >
    <Navbar />
    <div class="paste-list-area" :class="{ 'no-interact': sidebarIsOpen }">
      <PasteList />
    </div>
    <PasteInput />
  </main>
  <transition-group name="fade">
    <MainSettings v-if="showAppSettings" key="1" />
    <RoomSettings v-if="showRoomSettings" key="2" />
    <CreateRoom v-if="showCreateRoom" key="3" />
    <ShareQR v-if="showShareQR" key="4" />
    <LoginQR v-if="showLoginQR" key="5" />
    <QRScanner v-if="showQRScanner" key="6" />
    <LogMeInQR v-if="showLogMeInQR" key="7" />
    <ConfirmDeleteUser v-if="showDeleteUser" key="8" />
  </transition-group>
  <MaintenanceMode v-if="showMaintenanceMode" />
</template>

<script setup lang="ts">
  import { onMounted, ref, computed, onBeforeUnmount } from 'vue'
  import './css/main.scss'
  import { handlePaste } from './utils/clipboardUtils'
  import { startupService, touchService } from './services'
  import isMobile from './utils/isMobile'
  import devlog from './utils/log'
  import {
    ShareQR,
    MainSettings,
    RoomSettings,
    CreateRoom,
    MobilePasteActions,
    MaintenanceMode,
    Login,
    Signup,
    ResetPassword,
    EncryptionKey,
    EncryptionKeyInput,
    VerifyEmail,
    AskToVerifyEmail,
    FileDrop,
    UpdateOverlay,
    LoadingOverlay,
    LogMeInQR,
    QRScanner,
  } from './components/asyncComponents'
  import { useRoomsStore } from '/@/store/rooms'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'
  import isDev from '/@/utils/isDev'
  import isStaging from '/@/utils/isStaging'
  import { useUserStore } from '/@/store/user'
  import ConfirmDeleteUser from '/@/components/Settings/UserSettings/ConfirmDeleteUser.vue'

  // Dependencies

  const roomsStore = useRoomsStore()
  const userStore = useUserStore()
  const appState = useAppStateStore()
  const { loggedIn } = storeToRefs(userStore)
  const {
    showSignup,
    showMaintenanceMode,
    showAppSettings,
    showLoadingOverlay,
    showDeleteUser,
    showShareQR,
    showLoginQR,
    showLogMeInQR,
    showQRScanner,
    showEncryptionKey,
    showEncryptionKeyInput,
    showMobilePasteActions,
    showPastePreview,
    showUpdateOverlay,
    showEmailVerification,
    showPasswordReset,
    showAskForEmailVerification,
  } = storeToRefs(appState)
  const { showCreateRoom, showRoomSettings } = storeToRefs(roomsStore)

  // Data

  const mainElement = ref(null)
  const showFileDrop = ref(false)
  const showSidebar = ref(!isMobile)
  // !BETA
  const showBetaForm = ref(
    !(localStorage.getItem('pasta:betaFormSubmitted') === 'true') || !loggedIn,
  )

  // Computed

  const showAuthentication = computed(
    () =>
      appState.showAuthentication &&
      !appState.showEmailVerification &&
      !appState.showPasswordReset,
  )

  const sidebarIsOpen = computed(() => touchService.sidebarIsOpen)

  // Methods

  const handleTouchStart = (e: TouchEvent) => {
    touchService.handleTouchStart(e, 'main')
    showSidebar.value = true
  }
  const handleTouchDrag = (e: TouchEvent) => {
    touchService.handleTouchDrag(e)
  }
  const handleTouchEnd = () => {
    touchService.handleTouchEnd('main')
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    if (appState.isOfflineMode) return
    showFileDrop.value = true
  }
  const toggleFileDrop = () => {
    if (appState.isOfflineMode) return
    showFileDrop.value = !showFileDrop.value
  }
  const closeSidebar = async () => {
    if (!isMobile || !touchService.sidebarIsOpen) return
    await touchService.closeMenuOnTop()
  }

  // Hooks

  onMounted(async () => {
    // !BETA
    showBetaForm.value = !loggedIn && !import.meta.env.DEV
    if (isDev || isStaging) {
      const debugTools = await import('/@/utils/debug/addDebugUtilsToConsole')
      debugTools.addDebugUtilsToConsole()
    }
    appState.mainElement = mainElement.value
    appState.loadingOverlayProgress = 100
    await startupService.init()
  })

  onBeforeUnmount(() => {
    devlog('app', 'Unmount', 'Unmounting...')
    window.removeEventListener('paste', async (event) => {
      await handlePaste(event)
    })
  })
</script>

<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    color: #ececec;
    overflow: hidden;
    background-color: $background-primary;

    main {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $background-primary;
      z-index: 2;

      .paste-list-area {
        overflow-x: hidden;
        height: calc(100% - 61px);
      }
    }

    .sidebar-navbar-wrapper {
      display: flex;
    }

    @media (max-width: $mobile) {
      width: 100vw !important;
    }
  }
</style>
