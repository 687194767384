import getFilename from './getFilename'
import isDesktop from '/@/utils/isDesktop'
import { ipcService } from '/@/services'

const downloadLink = (url: string, filename: string): void => {
  download(url, filename)
}

const downloadBlob = (blob: Blob, filename: string): void => {
  console.log(blob, filename)
  // If filename has forward-slash, it's a firebase
  // reference and not a generic file name
  if (filename.match('/')) {
    filename = getFilename(filename)
  }
  if (isDesktop) {
    // Send file to electron instance
    ipcService.downloadFileViaElectron(blob, filename)
    return
  } else {
    const url = URL.createObjectURL(blob)
    download(url, filename)
  }
}

const download = (url: string, filename: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = filename ?? 'download'
  a.click()
  a.remove()
}

export { downloadBlob, downloadLink }
