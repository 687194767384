import devlog from '/@/utils/log'
import { Paste } from '/@/interfaces'
import {
  PastaIPCNames,
  PastaLocalStorageKeys,
  PastaWindowEvents,
} from '../../../../types'
import isDesktop from '/@/utils/isDesktop'
import { databaseService, sealdService } from '/@/services'
import { base64UrlToFile } from '/@/utils/files/base64UrlToFile'
import dayjs from 'dayjs'
import firebaseUploader from '/@/utils/firebase/firebaseUploader'
import bufferToFile from '/@/utils/files/bufferToFile'
import { UpdateDownloadedEvent } from 'electron-updater'
import { ProgressInfo } from 'electron-builder'
import { promptToEnableMacosTrustedAccessibility } from '/@/utils/settings/checkMacOsIsTrustedAccessibility'
import { isMac } from '/@/utils/isPlatform'
import { applyDesktopSettings } from '/@/utils/settings/applySettings'
import isOverUsageLimit from '/@/utils/files/isOverUsageLimit'
import { useAppStateStore } from '/@/store/appState'
import { useDeviceStore } from '/@/store/deviceStore'

export class ipcService {
  public startIPCListener(): void {
    if (!window.ipcRenderer) return
    this.handleApplyDesktopSettings()
    this.handleIPCCopy()
    this.handleToggleCopyAutoSend()
    this.handleToggleAutoDownload()
    this.handleWindowFocusClipboardRead()
    this.handleToggleClipboardAutoReceive()
    this.handleReceivingImage()
    this.handleReceiveCopiedFile()
    this.handleDesktopUpdateNotification()
    this.handleDesktopUpdateDownloadProgress()
    if (!isMac) return
    this.handleMacosPromptAllowAccessibility()
  }

  public handleWindowFocusClipboardRead() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(PastaIPCNames.windowFocus, (data: any) => {
      devlog('electron', 'IPC', 'Got clipboard data on window focus', data)
      if (appState.autoClipboardRead) {
        appState.pastePreview = data.text || data.image
      }
    })
  }

  public async handleAutoCopyIncomingTextToClipboard(data: Paste) {
    const appState = useAppStateStore()
    const deviceStore = useDeviceStore()
    if (!appState.clipboardAutoReceive) return
    devlog('electron', 'IPC', 'Writing incoming paste to clipboard', data)
    if (data.deviceId === deviceStore.currentDeviceId) return
    const text = await sealdService.decryptMessage(data.content)
    window.ipcRenderer.send(PastaIPCNames.clipboardWriteText, { text })
  }

  private handleIPCCopy() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(PastaIPCNames.copy, async (data: any) => {
      devlog('electron', 'IPC', 'Got clipboard data on copy', data)
      if (appState.copyAutoSend) {
        // TODO: Check if subscribed to channel
        devlog('electron', 'Clipboard', 'Auto sending copied data', data)
        await databaseService.createPaste(data.text, 'text/string')
      }
    })
  }

  private handleReceiveCopiedFile() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(
      PastaIPCNames.receiveCopiedFile,
      async (data: any) => {
        devlog('electron', 'IPC', 'Got clipboard file on copy', data)
        if (appState.copyAutoSend) {
          const file = bufferToFile(data.file, data.fileName, data.fileType)
          if (!file) return
          if (isOverUsageLimit([file])) {
            alert(
              'File to large! You will go over your storage usage limit, please delete some files first!',
            )
            return
          }
          await firebaseUploader(file, file.type)
        }
      },
    )
  }

  public updateTrayMenuItem(id: string, checked: boolean) {
    window.ipcRenderer.send(PastaIPCNames.updateTrayItem, { id, checked })
  }

  public updateTrayMenuConnectedStatus(status: 'connected' | 'disconnected') {
    if (!isDesktop) return
    window.ipcRenderer.send(PastaIPCNames.updateTrayConnected, { status })
  }

  private handleToggleCopyAutoSend() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(
      PastaIPCNames.toggleCopyAutoSend,
      (data: { value: boolean }) => {
        devlog('electron', 'IPC', 'Got toggle-copy-auto-send signal', data)
        appState.copyAutoSend = data.value
        localStorage.setItem(
          PastaLocalStorageKeys.COPY_AUTO_SEND,
          data.value.toString(),
        )
      },
    )
  }

  private handleToggleClipboardAutoReceive() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(
      PastaIPCNames.clipboardAutoSend,
      (data: { value: boolean }) => {
        devlog(
          'electron',
          'IPC',
          'Got pasta-toggle-clipboard-auto-receive signal',
          data,
        )
        appState.clipboardAutoReceive = data.value
        localStorage.setItem(
          PastaLocalStorageKeys.CLIPBOARD_AUTO_RECEIVE,
          data.value.toString(),
        )
      },
    )
  }

  private handleToggleAutoDownload() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(
      PastaIPCNames.toggleAutoDownload,
      (data: { value: boolean }) => {
        devlog('electron', 'IPC', 'Got pasta-toggle-auto-download signal', data)
        appState.autoDownload = data.value
        localStorage.setItem(
          PastaLocalStorageKeys.AUTO_DOWNLOAD,
          data.value.toString(),
        )
      },
    )
  }

  private handleReceivingImage() {
    window.ipcRenderer.receive(
      PastaIPCNames.receiveImage,
      async (data: { data: string }) => {
        devlog(
          'electron',
          'IPC',
          `Got ${PastaIPCNames.receiveImage} signal`,
          data,
        )
        if (!data) return
        const fileName = `clipboard-image_${dayjs().format(
          'DD-MM-YYYY-HH:mm:ss',
        )}`
        const imageFile = await base64UrlToFile(
          data.data,
          'image/png',
          fileName,
        )
        await firebaseUploader(imageFile, imageFile.type)
      },
    )
  }

  public downloadFileViaElectron(file: File | Blob, fileName: string) {
    const appState = useAppStateStore()
    const reader = new FileReader()
    reader.onload = function () {
      if (reader.readyState == 2) {
        if (!reader.result) return
        window.ipcRenderer.send(PastaIPCNames.downloadFile, {
          file: new Uint8Array(reader.result as ArrayBuffer),
          type: file.type,
          fileName,
          writeToClipboard: appState.clipboardAutoReceive,
        })
      }
    }
    reader.readAsArrayBuffer(file)
  }

  public async getAutoDownloadFolderLocation(): Promise<{
    folderPath: string
  }> {
    return new Promise((resolve, reject) => {
      // Sets up promise, resolves promise when IPCMain returns the folder path.
      window.ipcRenderer.send(PastaIPCNames.selectFolder)
      window.ipcRenderer.receive(
        PastaIPCNames.selectFolderResponse,
        (data: { folderPath: string }) => {
          if (!data) {
            reject()
          } else {
            resolve(data)
          }
        },
      )
    })
  }

  public async handleWindowEvents(command: PastaWindowEvents['command']) {
    window.ipcRenderer.send(PastaIPCNames.windowEvents, { command })
  }

  public async enableDebugMode(enable: boolean) {
    if (!isDesktop) return
    window.ipcRenderer.send(PastaIPCNames.debugMode, { enable })
  }

  handleDesktopUpdateNotification() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(
      PastaIPCNames.notifyDesktopUpdate,
      (data: { info: UpdateDownloadedEvent }) => {
        const { info } = data
        devlog('electron', 'IPC', `Update ready!`, info)
        appState.desktopUpdateReady = true
      },
    )
  }

  handleDesktopUpdateDownloadProgress() {
    const appState = useAppStateStore()
    window.ipcRenderer.receive(
      PastaIPCNames.updateDownloadProgress,
      (data: { amount: ProgressInfo }) => {
        const { amount } = data
        devlog('electron', 'IPC', `Downloading Update...`, amount.percent)
        appState.desktopUpdateDownloadProgress = amount
      },
    )
  }

  handleClickOnDesktopUpdateButton() {
    window.ipcRenderer.send(PastaIPCNames.quitAndUpdate)
  }

  public async returnCheckMacOsIsTrustedAccessibility(
    prompt = false,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.ipcRenderer.send(PastaIPCNames.MacOsIsTrustedAccessibility, {
        prompt,
      })
      window.ipcRenderer.receive(
        PastaIPCNames.MacOsIsTrustedAccessibility,
        (data: { isTrusted: boolean }) => {
          if (!data) {
            reject()
          } else {
            resolve(data.isTrusted)
          }
        },
      )
    })
  }

  handleMacosPromptAllowAccessibility() {
    window.ipcRenderer.receive(
      PastaIPCNames.MacOsPromptAccessibility,
      async () => {
        await promptToEnableMacosTrustedAccessibility()
      },
    )
  }

  handleApplyDesktopSettings() {
    window.ipcRenderer.receive(PastaIPCNames.applyDesktopSettings, () => {
      applyDesktopSettings()
    })
  }
}

export default new ipcService()
