import { getClipboardContents } from './clipboardUtils'
import devlog from './log'
import throttle from '/@/utils/throttle'
import isDesktop from '/@/utils/isDesktop'
import { useAppStateStore } from '/@/store/appState'

const handleAutoReadFocus = async (): Promise<void> => {
  const appState = useAppStateStore()
  if (!appState.allowClipboardAccess) return
  // devlog('app', 'Paste Auto Read', 'Auto Reading on document Focus')
  const clipboardItems = await getClipboardContents()

  if (!clipboardItems.length) return

  if (clipboardItems.find((item) => item.type.startsWith('image/'))) {
    const blob = clipboardItems.filter((item) => item.type.startsWith('image/'))
    if (blob?.[0]) {
      appState.pastePreview = blob?.[0]
    }
  } else {
    if (!appState.hasClipboardWriteAccess) return
    try {
      const text = await navigator.clipboard.readText()
      if (!text) return
      if (appState.pastePreview === text) {
        return
      } else {
        appState.pastePreview = text
      }
    } catch (e) {
      devlog('app', 'Paste Auto Read', 'Error reading clipboard', e)
    }
  }
}

const initAutoRead = (): void => {
  const appState = useAppStateStore()
  if (!appState.autoReadEventListenerAttached) {
    if (appState.autoClipboardRead || isDesktop) {
      window.addEventListener(
        'mousemove',
        throttle(handleAutoReadFocus, 80),
        false,
      )
      appState.autoReadEventListenerAttached = true
    }
  } else {
    if (!appState.autoClipboardRead) {
      disableAutoRead()
    }
  }
}

const disableAutoRead = (): void => {
  const appState = useAppStateStore()
  devlog('app', 'Settings', 'Disabling Auto Read')
  window.removeEventListener('mousemove', handleAutoReadFocus, false)
  appState.autoReadEventListenerAttached = false
}

export { initAutoRead, disableAutoRead, handleAutoReadFocus }
