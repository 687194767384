<template>
  <div class="paste-actions">
    <PsButton
      v-if="pasteType('image', 'file') || !pasteType('text/string')"
      :text="downloadButtonText"
      name="pasteAction"
      :callback="downloadPaste"
      type="large secondary"
      alt="Copy"
    >
      <template #icon>
        <i-feather-download />
      </template>
    </PsButton>
    <PsButton
      v-if="pasteType('image', 'text') && !pasteType('text')"
      ref="copyButton"
      text="Copy"
      name="pasteAction"
      :callback="copyImage"
      type="large secondary"
      alt="Copy"
    >
      <template #icon>
        <i-feather-copy />
      </template>
    </PsButton>
    <PsButton
      v-if="
        pasteType('text/string', 'text/plain') &&
        !(isMobile && pasteType('text')) &&
        !pasteType('image')
      "
      ref="copyButton"
      text="Copy"
      name="pasteAction"
      :callback="copyText"
      type="large secondary"
      alt="Copy"
    >
      <template #icon>
        <i-feather-copy />
      </template>
    </PsButton>
    <PsButton
      v-if="isMobile && supportsShare"
      text="Share"
      :callback="sharePaste"
      name="pasteAction"
      type="large secondary"
      alt="Share"
    >
      <template #icon>
        <i-feather-share />
      </template>
    </PsButton>
    <PsButton
      text="Share"
      :callback="openShareQR"
      name="pasteAction"
      type="large secondary"
      alt="QR"
    >
      <template #icon>
        <i-feather-share-2 />
      </template>
    </PsButton>
    <PasteActionDelete
      text=""
      :callback="deletePaste"
      name="pasteAction"
      :confirm-click="true"
      type="large secondary danger"
      alt="Delete"
    >
      <template #icon>
        <i-feather-x />
      </template>
    </PasteActionDelete>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import copy from 'clipboard-copy'
  import isMobile from '../../../utils/isMobile'
  import { supportsShare } from '/@/utils/pwa/share'
  import { Paste } from '/@/interfaces'
  import pasteActionsService from '../../../services/pasteActionsService'
  import { databaseService } from '/@/services'
  import { useAppStateStore } from '/@/store/appState'

  const props = defineProps<{
    paste: Paste
    pasteId: string
  }>()

  const appState = useAppStateStore()

  const copyButton = ref<null | HTMLInputElement>(null)

  const isDownloading = ref(false)

  const downloadButtonText = computed(() =>
    isDownloading.value ? 'Downloading' : 'Download',
  )

  const copyText = async () => {
    await copy(props.paste.content)
  }

  const downloadPaste = async () => {
    await pasteActionsService.downloadPaste(props.paste as Paste, props.pasteId)
  }

  const copyImage = async () => {
    await pasteActionsService.copyImage(props.paste as Paste)
  }

  const sharePaste = async () => {
    await pasteActionsService.shareAPISharePaste(props.paste as Paste)
  }

  const deletePaste = async () => {
    await databaseService.deletePaste(props.paste)
  }

  const openShareQR = () => {
    console.log(props.paste)
    appState.setCurrentSharePaste(props.paste)
    // appState.currentSharePaste = props.paste
    appState.showShareQR = true
  }

  const pasteType = (type1: string, type2 = null) => {
    return (
      props.paste.contentType.startsWith(type1) ||
      props.paste.contentType.startsWith(type2)
    )
  }
</script>

<style lang="scss" scoped>
  .paste-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 12px;
    gap: 8px;

    &__share-button {
      height: 24px;
      width: 20px;
      margin: 0;
    }

    @media (max-width: 1120px) {
      //display: block;
      button {
        //margin: 18px;
      }
    }

    @media (max-width: $mobile) {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 0;
      button:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .paste-actions--grid {
    width: 100%;
    height: 46px;
    justify-content: space-evenly;
    position: absolute;
    align-items: center;
    bottom: 0;
    right: 0;
    display: none;
    background: rgb(40, 40, 41);
    background: linear-gradient(
      302deg,
      rgba(40, 40, 41, 1) 45%,
      rgba(255, 255, 255, 0) 65%
    );

    .paste-actions__gradient {
      width: 50%;
      height: 100%;
    }

    .pasta-icon {
      height: 20px;
      width: 24px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
</style>
