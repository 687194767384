import isDev from '/@/utils/isDev'
import isStaging from '/@/utils/isStaging'

const useStagingEnv = isStaging

const firebaseConfig = {
  apiKey: 'AIzaSyBeXXWLFbp4KjgTtYUbWxN0wEdCzjrxiHg',
  authDomain: 'pasta-api.firebaseapp.com',
  projectId: 'pasta-api',
  storageBucket: 'pasta-api.appspot.com',
  messagingSenderId: '123329374122',
  appId: '1:123329374122:web:0ea9471455da2c9cf3add3',
  vapidKey:
    'BNl1EySh0KcTUyyaRLqBOyr-tQHJprVDtLM_fjLFpSi__72ISdbHAOzs9GdZnh5AanNOAYbaGYNGfLaRNl8YstY',
  databaseURL: 'https://pasta-api.europe-west1.firebasedatabase.app/',
  locationId: 'europe-west',
}

const firebaseConfigStaging = {
  apiKey: 'AIzaSyBP7XuS7Ovs-gX6auWegr4V785qKGWRSV0',
  authDomain: 'pasta-staging.firebaseapp.com',
  projectId: 'pasta-staging',
  storageBucket: 'pasta-staging.appspot.com',
  messagingSenderId: '360212001512',
  appId: '1:360212001512:web:6c8912d5f71923c4cc4f5d',
  vapidKey:
    'BNf-_kmnKSwil7dt-tQhjOcNZ1O8tffYdnGnzmIa0i0xMElq5cMYugnydjQeUnRAN6_JpmJEQ9EuWM84Pw7Fd4A',
  databaseURL:
    'https://pasta-staging-default-rtdb.europe-west1.firebasedatabase.app',
}

const config = useStagingEnv === 'true' ? firebaseConfigStaging : firebaseConfig

export default config
