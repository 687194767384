<template>
  <div class="app-settings">
    <div :style="style" class="app-settings--window">
      <div class="app-settings--window__container">
        <div class="app-settings--header">
          <div class="app-settings--header__categories">
            <slot name="categories" />
          </div>
          <i-feather-x
            class="app-settings--header__close"
            alt="Close"
            @click="closeAppSettings"
          />
        </div>
        <div class="app-settings--container">
          <slot name="settings"></slot>
        </div>
        <div class="app-settings__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
    <div class="app-settings--background" @click="closeAppSettings"></div>
  </div>
</template>

<script setup lang="ts">
  import { computed, defineProps, onBeforeUnmount, onMounted } from 'vue'
  import { useAppStateStore } from '/@/store/appState'
  const appState = useAppStateStore()

  const props = defineProps({
    closeSetting: {
      type: Function,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 620,
    },
    height: {
      type: Number || String,
      required: false,
      default: 'auto',
    },
    showBackgroundColor: {
      type: Boolean,
      required: false,
      default: true,
    },
  })

  const closeAppSettings = () => {
    props.closeSetting()
  }

  const style = computed(() => {
    return {
      height: props.height + 'px',
      width: props.width + 'px',
    }
  })

  const backgroundColor = computed(() => {
    return props.showBackgroundColor ? '#00000060' : 'transparent'
  })

  onMounted(() => (appState.settingsOpen = true))
  onBeforeUnmount(() => (appState.settingsOpen = false))
</script>

<style lang="scss">
  @import '../../css/card.scss';

  .app-settings {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background-color: v-bind(backgroundColor);

    &--window {
      @extend .pasta-card, .pasta-card--dark;
      color: #fff;
      z-index: 5;
      @media (max-width: $mobile) {
        height: 100% !important;
        width: 100% !important;
      }

      &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    &--background {
      position: absolute;
      height: 100vh;
      width: 100vw;
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 32px 24px;
      @media (max-width: $mobile) {
        padding: 24px 24px 32px 24px;
      }

      &__categories {
        display: flex;
        @media (max-width: $mobile) {
          font-size: 14px;
        }
      }

      &__close {
        width: 38px;
        height: 38px;
        float: right;
        cursor: pointer;
        padding: 7px;
        border-radius: 100px;
        transition: background-color 100ms ease-in-out;

        &:hover {
          background-color: $background-primary;
        }

        &:active {
          background-color: $background-sidebar;
        }
      }
    }

    &--container {
      overflow: auto;
      min-height: 260px;
      height: 100%;
      @media (max-width: $mobile) {
        max-height: unset;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      padding: 24px;

      button {
        margin-left: auto !important;
      }

      input {
        width: 100%;
        margin-right: 24px;
      }
    }
  }
</style>
