<template>
  <section ref="pasteListElement" class="paste-list">
    <div
      v-if="!isPasteListEmpty"
      class="paste-list__container"
      :class="{ 'paste-list__container--desktop': isDesktop }"
    >
      <Paste
        v-for="paste in pasteList"
        :key="paste.id"
        :paste="paste"
        :paste-id="paste.id"
      >
      </Paste>
    </div>
    <div v-show="loadingPastes" class="paste-list-empty">
      <LoadingSpinner :color="'#fff'" :size="20" />
      <h1>Loading</h1>
    </div>
    <div v-show="isPasteListEmpty && !loadingPastes" class="paste-list-empty">
      <h1 v-if="!isOfflineMode">No pastes yet...</h1>
      <h1 v-else>You are offline</h1>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { nextTick, onMounted, onUpdated, ref } from 'vue'
  import scrollPasteListToBottom from '../../utils/scrollPasteListToBottom'
  import isDesktop from '/@/utils/isDesktop'
  import { usePasteListStore } from '/@/store/pasteList'
  import { storeToRefs } from 'pinia'
  import { useAppStateStore } from '/@/store/appState'
  import delay from '/@/utils/delay'

  const appState = useAppStateStore()
  const pasteListStore = usePasteListStore()
  const { pasteList, isPasteListEmpty } = storeToRefs(pasteListStore)
  const { isOfflineMode, loadingPastes } = storeToRefs(appState)

  const pasteListElement = ref()

  onMounted(() => {
    appState.pasteListElement = pasteListElement.value
    nextTick(async () => {
      await delay(500)
      scrollPasteListToBottom()
    })
  })

  onUpdated(() => {
    nextTick(() => {
      scrollPasteListToBottom()
    })
  })
</script>

<style lang="scss" scoped>
  .paste-list {
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    &__container {
      // Flip the entire container and flip the children (pastes) because I'm
      // using this retarded hack to make divs grow upwards when images render
      // and shift the layout, that way if an image grow or shrinks the layout
      // shift happens above the viewport most of the time and the list doesnt
      // shrink or grow in front of you while scrolling and rendering new images
      transform: scaleY(-1);
      & > div {
        border-bottom: 1px solid #3d3d3d;
        transform: scaleY(-1);
      }

      &--desktop {
        margin-top: 50px;
      }
    }
  }

  .paste-list-empty {
    height: 100%;
    opacity: 0.2;
    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .loading-spinner {
      margin-top: 5px;
      margin-right: 12px;
    }
  }
</style>
