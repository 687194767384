import { useAppStateStore } from '/@/store/appState'

const scrollPasteListToBottom = (
  behavior: ScrollBehavior | undefined = undefined,
): void => {
  const appState = useAppStateStore()
  const pasteListElement = appState.pasteListElement
  if (pasteListElement) {
    ;(pasteListElement as HTMLElement).scrollTo({
      top: (pasteListElement as HTMLElement).scrollHeight,
      behavior: behavior,
    })
  }
}

export default scrollPasteListToBottom
