<template>
  <button
    ref="button"
    class="button paste-actions__delete-button"
    :class="[type, disabled ? 'disabled' : '']"
    @click.stop="onClick"
  >
    <span
      v-show="!showSuccessIcon && !showLoadingSpinner"
      class="button__icon-container"
    >
      <slot v-if="!showConfirm" name="icon" class="button__icon"></slot>
      <i-feather-alert-circle v-else class="button__checkmark" alt="Success" />
    </span>
    <LoadingSpinner
      v-if="isLoading || showLoadingSpinner"
      :color="'#fff'"
      :size="16"
    />
    <i-feather-check
      v-show="showSuccessIcon"
      class="button__checkmark"
      alt="Success"
    />
  </button>
</template>

<script setup lang="ts">
  import { defineProps, ref } from 'vue'
  import delay from '../../../utils/delay'

  const props = defineProps({
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'small primary',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    callback: {
      type: Function,
      required: true,
    },
    confirmClick: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    showSuccessTick: {
      type: Boolean,
      required: false,
      default: false,
    },
  })

  const button = ref(null)
  const showSuccessIcon = ref(false)
  const showLoadingSpinner = ref(false)
  const showConfirm = ref(false)

  const onClick = async () => {
    // Prevent calling twice while callback running
    if (showLoadingSpinner.value || showSuccessIcon.value) return
    // Logic for double click to confirm
    if (props.confirmClick) {
      if (!showConfirm.value) {
        showConfirm.value = true
        // Reset button to reconfirm after 5 seconds
        await delay(5000)
        showConfirm.value = false
        return
      }
    }
    showLoadingSpinner.value = true
    await props.callback()
    showLoadingSpinner.value = false
    if (!props.showSuccessTick) return
    showSuccessIcon.value = true
    await delay(1200)
    showSuccessIcon.value = false
  }
</script>

<style lang="scss" scoped>
  .button {
    color: $text-primary;
    width: 50px;
    min-width: 0;
    height: 43px;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 200ms ease-in-out;
    touch-action: manipulation;
    user-select: none;
    padding: 10px 0;

    .loading-spinner {
      margin-top: -3px;
    }

    &__icon-container {
      display: flex;
    }

    &:hover {
      background-color: $accent-hover;
    }

    &:active {
      background-color: $accent-active;
    }
  }

  .danger {
    background-color: #ac2a2a;

    &:hover {
      background-color: #9c1a1a;
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    filter: opacity(0.5) grayscale(1);
  }
</style>
