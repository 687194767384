<template>
  <aside ref="sidebar" class="sidebar">
    <WindowButtons v-if="isDesktop && isWindows" />
    <div v-if="isDesktop && isMac" class="sidebar__window-drag-area">
      <div class="sidebar__window-drag-area-inner"></div>
    </div>
    <section
      class="rooms"
      :class="{ 'rooms--desktop': isDesktop && !isWindows }"
    >
      <Room
        v-for="room in rooms"
        :key="room.name"
        :room-name="room.name"
        :room-id="room.id"
        :room-settings="room.settings"
      />
    </section>
    <span class="rooms__add" @click="createRoom">
      <i-feather-plus />
      Add Board
    </span>
  </aside>
</template>

<script setup lang="ts">
  import isDesktop from '/@/utils/isDesktop'
  import { isMac, isWindows } from '/@/utils/isPlatform'
  import { useRoomsStore } from '/@/store/rooms'
  import { storeToRefs } from 'pinia'

  const roomsStore = useRoomsStore()
  const { rooms } = storeToRefs(roomsStore)
  const createRoom = () => {
    roomsStore.showCreateRoom = true
  }
</script>

<style lang="scss" scoped>
  .sidebar {
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: $sidebar-width;
    min-width: $sidebar-width;
    width: $sidebar-width;
    background-color: $background-sidebar;
    transition: width 250ms ease-in-out;

    &__window-drag-area {
      position: absolute;
      top: 0;
      height: 28px;
      width: 100%;
      -webkit-user-select: none;
      -webkit-app-region: drag;
      transition: background-color 0.2s ease-in-out;
      z-index: 10;

      // Background to macos window buttons
      .sidebar__window-drag-area-inner {
        width: 70px;
        height: 100%;
        backdrop-filter: blur(4px);
        background-color: #2222223b;
        border-bottom-right-radius: 6px;
      }
    }

    .rooms {
      flex: 1;
      padding: 10px;
      overflow-y: auto;
      position: relative;

      &--desktop {
        padding-top: 28px;
      }

      &__add {
        width: fit-content;
        display: flex;
        margin: 10px;
        align-items: center;
        font-weight: bold;
        color: grey;
        cursor: pointer;
        border-radius: 50px;
        padding: 10px 20px 10px 10px;

        &:hover {
          color: #ececec;
          background-color: $background-paste;
        }

        .pasta-icon {
          margin-right: 8px;
          margin-left: 3px;
        }
      }
    }

    @media only screen and (hover: none) and (max-width: $mobile) {
      width: 80%;
      max-width: unset !important;
      position: absolute;
      height: 100%;
    }
  }
</style>
