import isMobile from '../isMobile'
import getFilename from '../files/getFilename'
import devlog from '../log'

const supportsShare = navigator.canShare

const shareAPI = (
  file: File | Blob | null,
  fileName = 'file',
  text = '',
): void => {
  if (isMobile.value && supportsShare) {
    devlog('pwa', 'Share', 'Will Share')
    if (file instanceof Blob) {
      file = new File([file as Blob], fileName, { type: file.type })
    }
    let data: ShareData
    if (!file && text) {
      data = {
        title: 'Shared from Pasta.to',
        text: text,
      }
    } else {
      data = {
        files: [file as File],
        title: getFilename(fileName) || file?.type,
        text: 'Shared from Pasta.to',
      }
    }
    navigator
      .share(data)
      .then(() => devlog('pwa', 'Share', 'Share was successful'))
      .catch((error) => devlog('pwa', 'Share - WARNING', 'Share failed', error))
  } else {
    devlog('pwa', 'Share - WARNING', "Your browser doesn't support sharing")
  }
}

export { supportsShare, shareAPI }
