import isStaging from '/@/utils/isStaging'
const isProd = window.location.href.includes('pasta.to')
// const isProd = true

let appUrl
if (isStaging) {
  appUrl = 'https://staging.app.pasta.to'
} else if (isProd) {
  appUrl = 'https://app.pasta.to'
} else {
  appUrl = 'https://localhost:3001/'
}

const endpoints = {
  appUrl,
  share: 'https://s.pasta.to/',
}

export default endpoints
