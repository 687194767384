<template>
  <button
    ref="button"
    class="button medium primary"
    type="submit"
    @click.prevent
  >
    <slot></slot>
  </button>
</template>

<style lang="scss">
  .button {
    color: $text-primary;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 200ms ease-in-out;
    touch-action: manipulation;

    &:hover {
      background-color: $accent-hover;
    }

    &:active {
      background-color: $accent-active;
    }
  }

  .medium {
    height: 38px;
  }

  .primary {
    background-color: $accent-primary;
  }
</style>
