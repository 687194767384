<template>
  <Overlay :opacity="45">
    <div
      class="pasta-beta-form pasta-card pasta-card--bright pasta-card--padded pasta-card--margined"
    >
      <div v-if="!isAlreadySubmitted">
        <h3>Where am I and what is this?</h3>
        <p>
          Pasta is a mobile and desktop app that aims to make file transfers
          between devices seamless, with a focus on clipboard features.
          <br />
          <br />
          Pasta is currently in development and won't be ready for a while yet.
          But if you want to try it, you can drop your email in the box below
          and I'll let you know when it's ready for a test run. Soon™
        </p>
        <br />
        <form
          data-netlify="true"
          class="beta-form"
          name="beta-form"
          method="POST"
        >
          <input type="hidden" name="form-name" value="pizzaOrder" />
          <label hidden>email</label>
          <input
            v-model="email"
            class="pasta-input pasta-input--bright"
            type="email"
            name="email"
            placeholder="your@email.com"
          />
          <div class="pasta-beta-buttons">
            <PsButtonSubmit @click="handleSubmit"> Let me know!</PsButtonSubmit>
            <div class="pasta-beta-login" @click="thisOnHasAccount">
              Already have an account?
            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <h3>Thanks!</h3>
        <p>I'll let you know when Pasta is ready for a test run.</p>
        <div class="pasta-beta-buttons">
          <small>Eventually...</small>
          <div class="pasta-beta-login" @click="thisOnHasAccount">
            Already have an account?
          </div>
        </div>
      </div>
    </div>
  </Overlay>
</template>

<script setup>
  import { computed, ref, defineProps, onBeforeMount } from 'vue'
  import { useUserStore } from '/@/store/user'

  const userStore = useUserStore()

  const props = defineProps({
    onHasAccount: {
      type: Function,
      required: true,
    },
  })

  const pressedSubmit = ref(false)
  const email = ref('')

  const isAlreadySubmitted = computed(() => {
    return (
      localStorage.getItem('pasta:betaFormSubmitted') === 'true' ||
      pressedSubmit.value
    )
  })

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('https://hook.eu1.make.com/thip1jwa48hl26p0noit50vojtgc6f4i', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email.value }),
    })
      .then(() => {
        localStorage.setItem('pasta:betaFormSubmitted', true)
        pressedSubmit.value = true
      })
      .catch((error) => alert(error))
  }

  const thisOnHasAccount = () => {
    localStorage.setItem('pasta:betaFormSubmitted', true)
    props.onHasAccount()
  }

  onBeforeMount(() => {
    if (userStore.loggedIn) {
      props.onHasAccount()
    }
  })
</script>

<style scoped lang="scss">
  .pasta-beta-form {
    max-width: 533px;
  }

  .beta-form {
    display: grid;
    gap: 16px;
  }

  .pasta-beta-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pasta-beta-login {
    font-weight: 600;
    color: #2f2f2f;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
