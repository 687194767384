<template>
  <div class="progress-bar">
    <div class="progress-bar__meter" :style="progressWidth"></div>
    <div
      class="progress-bar__background"
      :class="{ 'progress-bar__background-full': fullWidth }"
    ></div>
  </div>
  <div class="progress-bar__percent">
    {{ progressPercent }}
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  // Props

  const props = withDefaults(
    defineProps<{
      progress: number
      fullWidth?: boolean
    }>(),
    {
      fullWidth: false,
      progress: 0,
    },
  )

  // Computed

  const progressWidth = computed(() => {
    return {
      width: `${Math.floor(props.progress)}%`,
    }
  })

  const progressPercent = computed(() => {
    return `${props.progress.toFixed(2)}%`
  })
</script>

<style scoped lang="scss">
  .progress-bar {
    width: calc(100% - 150px);
    height: 25px;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: auto;
    @media (max-width: 500px) {
      width: calc(100% - 130px);
    }

    &__meter {
      width: 100%;
      height: 10px;
      background-color: $accent-primary;
      border-radius: 1.7em;
      margin-right: 14px;
      z-index: 1;
      transition: width ease-in-out 100ms;
    }

    &__background {
      width: 100%;
      height: 10px;
      background-color: #000;
      border-radius: 1.7em;
      padding-right: 28px;
      position: absolute;
    }

    &__percent {
      margin-left: 8px;
      margin-right: 8px;
    }

    &__background-full {
      width: 100% !important;
    }
  }
</style>
