import { LocalSettings } from '/@/interfaces'
import {
  PastaDesktopSetting,
  PastaLocalStorageKeys,
} from '../../../../../types'
import { ipcService } from '/@/services'
import isDesktop from '/@/utils/isDesktop'
import { getDesktopSettings } from './getSettings'
import { useAppStateStore } from '/@/store/appState'

const applySettings = (settings: LocalSettings): void => {
  const appState = useAppStateStore()
  Object.keys(settings).forEach((setting: string) => {
    if (appState[setting] !== undefined) {
      appState[setting] = settings[setting]
    }
  })

  // Apply local desktop only settings stored in localstorage.
  // Updates menu tray items in electron.
  if (!isDesktop) return
  //const desktopSettings = getDesktopSettings()
}

const applyDesktopSettings = () => {
  const appState = useAppStateStore()
  const desktopSettingsString = localStorage.getItem(
    PastaLocalStorageKeys.DESKTOP_SETTINGS
  )
  const desktopSettings = desktopSettingsString
    ? JSON.parse(desktopSettingsString)
    : getDesktopSettings()
  desktopSettings.forEach((localSetting: PastaDesktopSetting) => {
    // Determine whether the value of the local setting is a boolean string
    // or something else.
    const value =
      localSetting.value === 'true' || localSetting.value === 'false'
        ? localSetting.value === 'true'
        : localSetting.value
    if (localSetting.menuItemString) {
      ipcService.updateTrayMenuItem(
        localSetting.menuItemString,
        value as boolean
      )
    }
    // TODO: check this works
    if (appState[localSetting.storeName] !== undefined) {
      // console.log(appState[localSetting.storeName], 'Applying Desktop Settings')
      appState[localSetting.storeName] = value
    }
  })
}

export { applySettings, applyDesktopSettings }
