<template>
  <Overlay>
    <div
      class="pasta-authentication pasta-card pasta-card--dark pasta-card--padded pasta-card--margined"
    >
      <div class="pasta-authentication__header-container">
        <SettingsCategoryButton
          class="pasta-authentication__header"
          :selected="true"
        >
          <p>Confirm Delete User</p>
        </SettingsCategoryButton>
      </div>
      <div class="danger-text">
        <p>
          <b>IMPORTANT:</b>
          <br />
          <br />
          Are you sure you want to delete your account and all associated data?
          <br />
          <br />
          <b
            >All data on the account will be deleted and your subscription will
            be cancelled immediately. <u>This cannot be undone.</u></b
          >
          <br />
          <br />
          Enter your email to confirm account deletion.
        </p>
      </div>

      <div class="pasta-login-inputs">
        <input
          ref="emailInput"
          v-model="emailInputValue"
          type="text"
          class="pasta-login-inputs__email"
          :autofocus="true"
        />
      </div>
      <div class="pasta-login-buttons">
        <PsButton
          class="pasta-login-buttons__signup"
          text="Delete User"
          :callback="handleDeleteUser"
          :show-success-tick="true"
          type="large danger"
          :disabled="disableDeleteUserButton"
        />
        <PsButton
          class="pasta-login-buttons__signup"
          text="Back"
          :callback="closeConfirmDeleteUser"
          type="large primary"
        />
      </div>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
  import { useAppStateStore } from '/@/store/appState'
  import { userService } from '/@/services'
  import { computed, onMounted, ref } from 'vue'
  import { useUserStore } from '/@/store/user'
  import { storeToRefs } from 'pinia'

  // Dependencies

  const appState = useAppStateStore()
  const userStore = useUserStore()

  const { showDeleteUser } = storeToRefs(appState)

  // Data

  const emailInputValue = ref('')
  const emailInput = ref()

  // Methods

  const closeConfirmDeleteUser = () => {
    showDeleteUser.value = false
  }

  const handleDeleteUser = async () => {
    await userService.deleteUser()
  }

  // Computed

  const disableDeleteUserButton = computed(() => {
    return userStore.user?.email !== emailInputValue.value
  })

  // Hooks

  onMounted(() => {
    emailInput.value.focus()
  })
</script>

<style lang="scss" scoped>
  @import '../../../css/authentication.scss';

  .confirm-delete-container {
    margin-top: 0;
    &__buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
  }

  .pasta-login-buttons {
    &__signup {
      :deep(.loading-spinner) {
        margin-top: -19px;
      }
    }
  }

  .danger-text {
    border: 1px solid red;
    border-radius: 4px;
    padding: 12px 17px;
    background-color: #452424;
    margin-bottom: 16px;
  }
</style>
