const preventClose = () => {
  window.onbeforeunload = () => {
    return ''
  }
}

const stopPreventClose = () => {
  window.onbeforeunload = null
}

export { preventClose, stopPreventClose }
