<template>
  <div
    ref="pastePreviewElementRef"
    v-tippy="{
      content: 'Click to send paste',
      delay: 1000,
      placement: 'bottom',
      touch: false,
    }"
    class="paste-preview"
    :class="{ hidden: !show }"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
    @click="sendPaste"
  >
    <div
      v-show="imageSrc"
      class="paste-preview-image--container notification-pill"
    >
      <div class="paste-preview-image--container__overlay">
        <i-feather-send />
      </div>
      <img :src="imageSrc" alt="Preview" />
    </div>
    <div
      v-show="!imageSrc && pastePreview"
      class="paste-preview-text--container notification-pill"
    >
      <div class="paste-preview-text--container__overlay">
        <i-feather-send />
      </div>
      <p class="truncate">{{ pastePreview }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue'
  import uploadFile from '../../utils/firebase/firebaseUploader'
  import delay from '../../utils/delay'
  import { databaseService, touchService } from '../../services'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'
  import { directive as vTippy } from 'vue-tippy'
  import scrollPasteListToBottom from '/@/utils/scrollPasteListToBottom'

  const appState = useAppStateStore()
  const { pastePreview, pastePreviewElement } = storeToRefs(appState)
  const pastePreviewElementRef = ref(null)

  const imageRendered = ref(false)
  const imageSrc = ref<string | null>(null)
  const show = ref(false)

  onMounted(() => {
    appState.pastePreviewElement = pastePreviewElementRef.value
  })

  const renderImage = (file: File | Blob) => {
    imageSrc.value = URL.createObjectURL(file)
    imageRendered.value = true
  }

  const renderImageDesktop = (dataURL: string) => {
    imageSrc.value = dataURL
    imageRendered.value = true
  }

  const convertDataURIToFile = async (DataURI: string) => {
    const blob = await (await fetch(DataURI.toString())).blob()
    return new File([blob], 'image.png', {
      type: 'image/png',
    })
  }

  const sendPaste = async () => {
    // If is an image
    if (imageRendered.value) {
      let file
      // If in electron mode, convert data URI to blob then file
      if (window.isDesktop) {
        file = await convertDataURIToFile(pastePreview.value as string)
      }

      if (!window.isDesktop) {
        file = pastePreview.value
      }

      scrollPasteListToBottom('smooth')

      await uploadFile(file as File, (file as File).type)
    } else {
      await databaseService.createPaste(
        pastePreview.value as string,
        'text/string',
      )
    }
    await hidePreview()
    appState.pastePreview = null
  }

  const hidePreview = async () => {
    show.value = false
    await delay(800)
    imageSrc.value = null
    imageRendered.value = false
  }

  const onTouchStart = (e: TouchEvent) => {
    touchService.handleTouchStart(e, 'pastePreview')
  }

  const onTouchMove = (e: TouchEvent) => {
    touchService.handleTouchDrag(e)
  }

  const onTouchEnd = () => {
    touchService.handleTouchEnd('pastePreview')
  }

  watch(pastePreview, (contents) => {
    // If in desktop mode, electron sends a DATA URL
    if (
      window.isDesktop &&
      typeof contents === 'string' &&
      contents.match('data:image/png;base64,')
    ) {
      renderImageDesktop(contents)
      return
    }

    // If in browser/mobile mode, preview will be a typeof File
    if (
      !window.isDesktop &&
      typeof contents === 'object' &&
      contents?.type?.startsWith('image')
    ) {
      renderImage(contents)
      show.value = true
      touchService.slideElement(0, pastePreviewElement.value)
      return
    }

    if (typeof contents === 'string') {
      show.value = true
      touchService.slideElement(0, pastePreviewElement.value)
      imageSrc.value = null
      imageRendered.value = false
      return
    } else {
      show.value = false
    }
  })

  window.addEventListener('keypress', (e) => {
    if (
      e.key === 'Enter' &&
      pastePreview.value &&
      !appState.pasteInputFocused &&
      !appState.settingsOpen
    ) {
      sendPaste()
    }
  })
</script>

<style lang="scss" scoped>
  .paste-preview {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 60px;
    user-select: none;
    border-radius: 1.7em;
    -webkit-tap-highlight-color: transparent;
    z-index: 5;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }

    .paste-preview-image--container,
    .paste-preview-text--container {
      max-width: 230px;
      max-height: 500px;
      box-shadow:
        0 0 10px rgb(0 0 0 / 30%),
        0 0 0 rgb(0 0 0);
    }

    .paste-preview-image--container__overlay,
    .paste-preview-text--container__overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 1.7em;
      .pasta-icon {
        position: absolute;
        opacity: 0;
        height: 35px;
        width: 35px;
      }

      &:hover {
        //background-color: #373a3e78;

        & > .pasta-icon {
          opacity: 1;
        }
      }

      & > div {
        border-radius: 100%;
        height: 100%;
        width: 100%;
        opacity: 0;
      }
    }

    .paste-preview-image--container {
      width: 100%;
      position: relative;
      padding: 0 !important;

      img {
        max-height: 350px;
        border-radius: 1.7em;
        background-color: #373a3e;
        min-width: 100px;
      }
    }

    .paste-preview-text--container {
      a {
        color: #71efde;
      }

      &__overlay {
        top: 0;
        left: 0;

        & > div {
          height: 100%;
          width: 100%;
          background-size: 28px;
        }
      }
    }
  }

  .hidden {
    //transform: translateX(150%);
  }

  .visible {
    //transform: translateX(150%) !important;
  }
</style>
