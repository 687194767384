import isDev from '/@/utils/isDev'

const devlog = (
  type:
    | 'init'
    | 'service'
    | 'error'
    | 'fetch'
    | 'app'
    | 'pwa'
    | 'websocket'
    | 'auth'
    | 'electron',
  area: string,
  message: string,
  extra: unknown | string = ''
): void => {
  const devLogging = isDev
    ? true
    : localStorage.getItem('pasta:devLogging') === 'true'
  if (!devLogging) return
  let color
  let typeText
  const dividerStyle = 'background: unset; color: white; font-weight: bold'
  const backgroundColor = (color: string) =>
    `background-color: ${color}; color: white; font-weight: bold`
  const doLog = (type: string, color: string) =>
    console.info(
      `%c${type}%c - %c${area}%c -`,
      backgroundColor(color),
      dividerStyle,
      backgroundColor('unset'),
      dividerStyle,
      message,
      extra
    )
  switch (type) {
    case 'init':
      typeText = 'Init'
      color = 'green'
      doLog(typeText, color)
      break
    case 'service':
      typeText = 'Service'
      color = '#a341c2'
      doLog(type, color)
      break
    case 'error':
      typeText = 'ERROR'
      color = '#bf0000'
      doLog(type, color)
      break
    case 'fetch':
      typeText = 'Fetch'
      color = '#bd8400'
      doLog(type, color)
      break
    case 'app':
      typeText = 'App'
      color = '#3fb983'
      doLog(type, color)
      break
    case 'pwa':
      typeText = 'PWA'
      color = '#1a9db4'
      doLog(type, color)
      break
    case 'websocket':
      typeText = 'Websocket'
      color = '#811ab4'
      doLog(type, color)
      break
    case 'auth':
      typeText = 'Auth'
      color = '#839b15'
      doLog(type, color)
      break
    case 'electron':
      typeText = 'Electron'
      color = '#9febf9'
      doLog(type, color)
      break
  }
}

export default devlog
