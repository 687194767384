import { getDeviceName, getPlatform } from '../utils/devices/getDeviceName'
import devlog from '../utils/log'
import fcmService from './fcmService'
import { PastaLocalStorageKeys } from '../../../../types'
import { Device } from '/@/interfaces'
import { databaseService } from '/@/services/index'
import { useDeviceStore } from '/@/store/deviceStore'
import { requestAndSetNotificationPermission } from '/@/utils/notificationUtils'
import isDesktop from '/@/utils/isDesktop'
import logout from '/@/utils/logout'

class deviceService {
  public async initDevice(): Promise<void> {
    fcmService.initFCMServiceWorker()
    const deviceStore = useDeviceStore()
    const localDevice: Device | null = JSON.parse(
      localStorage.getItem(PastaLocalStorageKeys.DEVICE) || '{}',
    )
    const localDeviceExistsInDevicesList = deviceStore.devices.find(
      (device: Device) => device.id === localDevice?.id,
    )

    // If a local device is present and matches a saved device, set it up.
    if (localDevice && !!localDeviceExistsInDevicesList) {
      await this.setupExistingDevice(localDevice)
    } else {
      await this.createNewDevice()
    }
  }

  public async setupExistingDevice(localDevice: Device) {
    const deviceStore = useDeviceStore()
    const notificationPermission = await requestAndSetNotificationPermission()
    const notificationsGranted = notificationPermission === 'granted'
    if (localDevice.shouldLogOut) {
      await this.removeDevice(localDevice, true)
      await logout()
    }
    deviceStore.currentDevice = localDevice

    // If there's an FCM ID present, start the FCM service worker.
    if (notificationsGranted && localDevice.fcmId && !isDesktop) {
      fcmService.initForegroundFCM()
    } else if (notificationsGranted && !localDevice.fcmId && !isDesktop) {
      await this.setDeviceFCMId(localDevice)
    }
    // Set the current device to the local device in store and local storage.
    localStorage.setItem(
      PastaLocalStorageKeys.DEVICE,
      JSON.stringify(localDevice),
    )
    devlog('init', 'Device', 'Set up existing device')
  }

  public async setDeviceFCMId(device: Device) {
    device.fcmId = await fcmService.getFCMToken()
    await databaseService.editDevice(device)
    fcmService.initFCMServiceWorker()
    fcmService.initForegroundFCM()
  }

  public async createNewDevice() {
    let fcmId = null
    const deviceStore = useDeviceStore()
    const platform = getPlatform()
    const notificationPermission = await requestAndSetNotificationPermission()
    const notificationsGranted = notificationPermission === 'granted'

    if (notificationsGranted && !isDesktop) {
      fcmId = await fcmService.getFCMToken()
    }

    const newDevice: Device = {
      title: getDeviceName(),
      type: platform.type,
      fcmId: fcmId,
    }

    const addedDevice = await databaseService.addDevice(newDevice)

    if (!addedDevice || !addedDevice.key) {
      console.error('Failed to add device')
      return
    }

    newDevice.id = addedDevice.key
    deviceStore.currentDevice = newDevice
    localStorage.setItem(
      PastaLocalStorageKeys.DEVICE,
      JSON.stringify(newDevice),
    )

    if (notificationsGranted && newDevice.fcmId) {
      fcmService.initFCMServiceWorker()
      fcmService.initForegroundFCM()
    }

    devlog('init', 'Device', 'Set up a new device')
  }

  public async editDevice(
    device: Device,
    title: string,
    saveLocalDevice = false,
  ) {
    device.title = title
    if (saveLocalDevice)
      localStorage.setItem(PastaLocalStorageKeys.DEVICE, JSON.stringify(device))
    await databaseService.editDevice(device)
  }

  // Instead of just deleting the device, we mark it for deletion. Backend
  // cron job will delete it after 60 days. If a user logs in with a device
  // that is marked for deletion, it will be removed from the database after
  // logging the user out.
  public async markDeviceForDeletion(device: Device) {
    device.shouldLogOut = true
    await databaseService.editDevice(device)
  }

  public async removeDevice(device: Device, removeLocalDevice = false) {
    if (removeLocalDevice) localStorage.removeItem(PastaLocalStorageKeys.DEVICE)
    await databaseService.removeDevice(device)
  }
}

export default new deviceService()
