import { idbService, sealdService } from '../../services'
import devlog from '../log'
import getFileURL from './getFileURL'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getApp } from 'firebase/app'

const firebaseDownloader = async (
  filePath: string,
  pasteId: string,
): Promise<{ timeTaken: number; imageBlob: Blob }> => {
  if (import.meta.env.VITE_DEV_EMULATORS) {
    // Emulator
    const app = getApp()
    const storage = getStorage(app)
    connectStorageEmulator(storage, '127.0.0.1', 9199)
    // Emulator
  }
  const startTime = new Date().getTime()
  const imageBlob = await getFileURL(filePath)
    .then(async (url: RequestInfo) => {
      return await fetch(url, {
        method: 'GET',
      })
        .then((response) => response.blob())
        .then(async (blob) => {
          const decrypted = await sealdService.decryptFile(blob, pasteId)
          if (!decrypted) throw new Error('Unable to decrypt')
          return decrypted
        })
    })
    .catch(async (error: any) => {
      devlog('error', 'Firebase Download', error, pasteId)
      await idbService.removePaste(pasteId)
      throw new Error(error)
    })
  const endTime = new Date().getTime()
  const timeTaken = endTime - startTime
  return { imageBlob, timeTaken }
}

export { firebaseDownloader }
