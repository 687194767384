<template>
  <circle-progress
    v-tippy="{
      content: `Downloading Update ${amountDownloaded}MB/${size}MB`,
      delay: [600, 250],
      placement: 'bottom',
      touch: false,
    }"
    :class="'pasta-progress-circle'"
    :size="20"
    :fill-color="'#cacaca'"
    :border-width="2"
    :border-bg-width="0"
    :percent="props.progress.percent"
  />
</template>

<script setup lang="ts">
  import 'vue3-circle-progress/dist/circle-progress.css'
  import CircleProgress from 'vue3-circle-progress'
  import { computed, defineProps } from 'vue'
  import { ProgressInfo } from 'electron-builder'
  import { directive as vTippy } from 'vue-tippy'

  const props = defineProps<{
    progress: ProgressInfo
  }>()

  const size = computed(() =>
    Math.floor(props.progress.total / Math.pow(1024, 2))
  )
  const amountDownloaded = computed(() =>
    Math.floor(props.progress.transferred / Math.pow(1024, 2))
  )
</script>

<style scoped lang="scss">
  .pasta-progress-circle {
    margin-right: 8px;
    transform: scale(1.03);
  }
</style>
