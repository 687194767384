import devlog from '/@/utils/log'
import { uploadBytes, ref, getStorage, getDownloadURL } from 'firebase/storage'
import { getAuth, updateProfile } from 'firebase/auth'
// @ts-ignore
import hashAvatar from 'hash-avatar'
import { deleteUser } from 'firebase/auth'
import logout from '/@/utils/logout'
import { databaseService, idbService, sealdService } from '/@/services/index'
import { URLUserActionsParams } from '/@/interfaces'
import { PastaLocalStorageKeys } from '../../../../types'
import { preventClose, stopPreventClose } from '/@/utils/preventClose'
import { getApp } from 'firebase/app'
import { useAppStateStore } from '/@/store/appState'

class userService {
  get currentUser() {
    const auth = getAuth()
    return auth?.currentUser
  }

  get userCreatedAt() {
    const auth = getAuth()
    if (!auth?.currentUser?.metadata.creationTime) return
    return new Date(auth?.currentUser?.metadata.creationTime)
  }

  isUserVerified() {
    return this.currentUser?.emailVerified
  }

  get currentUserActionRoute() {
    const params = new URLSearchParams(window.location.search)
    const mode = params.get(URLUserActionsParams.mode)
    const oobCode = params.get(URLUserActionsParams.oobCode)
    return {
      mode,
      oobCode,
    }
  }

  async handleUserActionsRoute() {
    const appState = useAppStateStore()
    if (this.currentUserActionRoute.mode === URLUserActionsParams.verifyEmail) {
      appState.showEmailVerification = true
      return true
    }
    if (
      this.currentUserActionRoute.mode === URLUserActionsParams.resetPassword
    ) {
      appState.showPasswordReset = true
      return true
    }
  }

  async updateUserProfilePicture(file: File) {
    if (file.size > 4000000) return
    const app = getApp()
    const storage = getStorage(app)
    const auth = getAuth()
    if (!auth.currentUser) return
    const storageRef = ref(
      storage,
      `profilePictures/${auth.currentUser.uid}/pic`,
    )
    await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)
    await updateProfile(auth.currentUser, { photoURL: url })
    await idbService.storeUserProfilePicture(file)
    localStorage.setItem(PastaLocalStorageKeys.PROFILE_PICTURE_URL, url)
    devlog('service', 'Firebase', 'Updated profile picture', file)
  }

  async handleStoreUpdatedProfilePic(url: string | null) {
    if (!url) return
    if (url === localStorage.getItem(PastaLocalStorageKeys.PROFILE_PICTURE_URL))
      return
    const file = await fetch(url, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        return blob
      })
    await idbService.storeUserProfilePicture(file)
    localStorage.setItem(PastaLocalStorageKeys.PROFILE_PICTURE_URL, url)
  }

  async generateRandomProfilePicture(rand: string | null = null) {
    const currentUser = getAuth().currentUser
    const type = 'image/svg+xml'
    const svg = hashAvatar(rand || currentUser?.uid, { size: 100 })
    const blob = new Blob([svg], { type })
    const file = new File([blob], 'pic', { type })
    await this.updateUserProfilePicture(file)
  }

  async deleteUser() {
    preventClose()
    const appState = useAppStateStore()
    try {
      appState.isLoggingOut = true
      const auth = getAuth()
      if (!auth.currentUser) return
      await deleteUser(auth.currentUser)
      stopPreventClose()
      await logout()
    } catch (e) {
      console.error(e)
      appState.isLoggingOut = false
      stopPreventClose()
    }
  }
}

export default new userService()
