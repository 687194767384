import notificationSound from '../../assets/sounds/notification.mp3'
import { useAppStateStore } from '/@/store/appState'

export const requestAndSetNotificationPermission = async (
  onNotificationsGrantedCallback?: () => any,
): Promise<'granted' | 'default' | 'denied'> => {
  const appState = useAppStateStore()
  const localPermission = localStorage.getItem('pasta:notificationPermission')
  if (!window.Notification || localPermission === 'denied') {
    appState.showNotifications = false
    return 'denied'
  }
  const permissionResult = await Notification.requestPermission()
  localStorage.setItem('pasta:notificationPermission', permissionResult)
  if (permissionResult === 'granted') {
    appState.showNotifications = true
    onNotificationsGrantedCallback?.()
  }
  return permissionResult
}

export const showNotification = async (
  content = 'Paste',
  callback?: (reloadPage?: boolean) => Promise<void>,
): Promise<void> => {
  const appState = useAppStateStore()
  if (
    window.Notification &&
    Notification.permission !== 'denied' &&
    appState.showNotifications
  ) {
    const audio = new Audio(notificationSound)
    const notification = new Notification('New Paste', {
      body: content,
      icon: '/favicon.ico',
    })
    notification.onclick = function (e: Event) {
      const target = e.target as Notification
      window.focus()
      target.close()
    }
    await audio.play()
    if (callback) {
      notification.onclick = function () {
        callback()
      }
    }
  }
}
