import platform from './platform'
import capitalize from '/@/utils/capitalize'

const getDeviceName = (): string => {
  return `${platform.browser.name} on ${platform.os.name}`
}

const getPlatform = (): { os: string; type: string; version: string } => {
  return {
    os: platform.os.name,
    type: capitalize(platform.platform),
    version: platform.os.version,
  }
}

export { getPlatform, getDeviceName }
